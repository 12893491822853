/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import {capitalizeFirstLetter, getFullDateDisplay, getPath} from "../../../../../../lib/utils/";
import TransactionItem from "./TransactionItem/";
import Table, {FilterByDate} from "../../../../../Widgets/Table";
import getEnvironmentName from "../../../../../../lib/getEnvironmentName";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import consoleLogger from "../../../../../../lib/consoleLogger";

const Component: React.FunctionComponent<any> = props => {
    const exportCsv = () => {
        const accountName = props.accounts.selected.data.name;
        const environmentId = props.dataSources.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, props.environments);
        const dataSourceName = props.dataSources.selected.data.name;
        axiosWrapper(props.config.analyticsService, "gtw-export-csv", "POST", props.state.transactions.data, {}, "blob")
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const fileName = `${accountName}_${environmentName}_${dataSourceName}_da-transactions-list-${getFullDateDisplay(Date.now(), props.ui.timeZone)}.csv`;
                const sanitizedFileName = encodeURI(fileName);
                link.setAttribute("download", sanitizedFileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                consoleLogger.log(error);
            });
    };
    let transactions = getPath(props, "state.transactions.data") || [];
    if (!props.state.showAll) {
        transactions = transactions.slice(0, 100);
    }
    const columns = [
        {
            name: "time",
            size: 2,
            label: "Time",
            sort: () => props.onSetState({sortBy: {["time"]: !getPath(props, "state.sortBy.time")}}),
            order: getPath(props, "state.sortBy.time")
        },
        {
            name: "client",
            size: 2,
            label: "Client",
            sort: () => props.onSetState({sortBy: {["client"]: !getPath(props, "state.sortBy.client")}}),
            order: getPath(props, "state.sortBy.client")
        },
        {
            name: "user",
            size: 2,
            label: "User",
            sort: () => props.onSetState({sortBy: {["user"]: !getPath(props, "state.sortBy.user")}}),
            order: getPath(props, "state.sortBy.user")
        },
        {
            name: "resourceType",
            size: 2,
            label: "Resource",
            sort: () => props.onSetState({sortBy: {["resourceType"]: !getPath(props, "state.sortBy.resourceType")}}),
            order: getPath(props, "state.sortBy.resourceType")
        },
        {
            name: "operationType",
            size: 2,
            label: "Operation",
            sort: () => props.onSetState({sortBy: {["operationType"]: !getPath(props, "state.sortBy.operationType")}}),
            order: getPath(props, "state.sortBy.operationType")
        },
        {
            name: "latencyType",
            size: 1,
            label: "Latency",
            sort: () => props.onSetState({sortBy: {["latencyType"]: !getPath(props, "state.sortBy.latencyType")}}),
            order: getPath(props, "state.sortBy.latencyType")
        }
    ];
    const transactionsList = transactions.map((transaction, index) => <TransactionItem key={`${transaction.timestamp}-${index}`}{...props} transaction={transaction}/>);
    const checkAll = set => {
        const operations = {...props.state.filters.operations};
        Object.keys(operations).forEach((key) => operations[key] = set);
        props.onSetState({
            filters: {
                ...props.state.filters,
                operations
            }
        })
    }
    const filteredOperations = Object.keys(props.state.filters.operations).filter(key => props.state.filters.operations[key]);
    let labelOperations = "Operations";
    if (filteredOperations.length) {
        labelOperations = capitalizeFirstLetter(filteredOperations[0]);
        if (filteredOperations.length > 1) {
            labelOperations += ` + ${filteredOperations.length - 1}`;
        }
    }
    const filters = [
        {
            type: "component",
            value: <FilterByDate {...props} key="data_filter" setFilterItem={(item, start, end) => setFilter(props, item, start, end)}/>
        },
        {
            type: "multiple",
            name: "operations",
            title: "Operations",
            checkAllLabel: "Select All Operations",
            label: labelOperations,
            isActive: filteredOperations.length > 0,
            checked: props.state.filters.operations,
            values: Object.keys(props.state.filters.operations),
            allSelected: filteredOperations.length === Object.keys(props.state.filters.operations).length,
            checkAll: val => checkAll(val),
            onDelete: () => checkAll(false),
            onClick: key => props.onSetState({filters: {...props.state.filters, operations: {...props.state.filters.operations, [key]: !props.state.filters.operations[key]}}})
        }
    ];

    return <Table total={`Showing ${transactionsList.length} transactions`} rightFilters={filters} export={[{func: exportCsv, label: "Export CSV"}]} columns={columns}>
        {transactionsList}
    </Table>;
}

const setFilter = (props, item, start, timeframe) => {
    let state = {};
    if (item === "startTime") {
        state["timeframe"] = start === -1 ? start: timeframe;
    } else if (item === "period") {
        state = {...start};
    }
    props.onSetState(state);
};

Component.defaultProps = {
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
