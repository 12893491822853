import moment from "moment";
import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import getLocationInfo from "../../../../lib/getLocationInfo";
import {getPath} from "../../../../lib/utils/";
import {Link} from "react-router-dom";
import IconApps from "@material-ui/icons/Apps";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2, NavbarL3} from "../../../CustomNavbar/";
import Selector from "../../../CustomNavbar/Selector";
import Activations from "./Activations/";
import Details from "./Details/";
import Dialogs from "./Dialogs/";
import Usage from "./Usage/";
import LoggingTab from "../../../Widgets/LoggingTab";
import InitMachine from './InitMachine'
import SessionInspector from './SessionInspector'
import Launches from './Launches'

let TABS = [
    {
        title: "DETAILS",
        "data-qa": "apps-details-tab",
        error: false
    },
    {
        title: "ACTIVATIONS",
        "data-qa": "apps-activations-tab",
        error: false
    },
    {
        title: "USAGE",
        "data-qa": "apps-usage-tab",
        error: false
    },
    {
        title: "LOGGING",
        "data-qa": "apps-logging-tab",
        error: false
    },
    {
        title: "SESSION INSPECTOR",
    },
    {
        title: "LAUNCHES",
        "data-qa": "apps-launches-tab",
        error: false
    }
];

export const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "recalc" | "wait" | "w-init" | "e-init"

    data: {
        smartLaunchProxyInfo: {}
    },
    dataValidation: {},

    logoStringType: "", // "" | ":URL:" | ":FILE:"

    selectedActivation: {},
    selectedAppLaunch: {},

    mode: ":VIEW:", // ":VIEW:" | ":EDIT:"

    wstep: 0,
    isNextWStepEnabled: false,

    etab: null,

    dialog: "",

    proxyAuthentication: false,

    loadingLogs: true,

    logsFilter: {
        startTime: moment().startOf("day"),
        logsPageSize: 50
    },

    selectedSessionId: '',
    selectedMetadata: '',

    activations: [],
}

class Component extends React.Component<any, any> {
    public static propTypes = {
        apps: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,

        dataSourcesReadAll: PropTypes.func.isRequired,
        gatewaysReadAll: PropTypes.func.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        this.props.uiTabsSetSelected(0)
        this.init()
    }

    public componentDidUpdate(prevProps) {
        const selectedTab = getPath(this.props, "ui.tabs.selected");
        const prevSelectedTab = getPath(prevProps, "ui.tabs.selected");

        if (selectedTab !== prevSelectedTab) {
            this.init()
        }
    }

    public render() {
        const {env, appId: selectedApp} = getLocationInfo(this.props.location, this.props.config);
        return <div style={{width: "100%"}}>
            <InitMachine
                parentState={this.state as any}
                onSetParentState={this.onSetState}
            />

            <NavbarL2 title={(
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{height: "56px", marginRight: "24px"}}>
                        <Link to={`/${env}/apps`} style={{color: "#fff", fontSize: "24px", textDecoration: "none"}}>
                            Apps
                        </Link>
                    </div>
                    <Selector data-qa="navbar-app-selector-name" value={selectedApp} icon={<IconApps style={{color: "rgba(255, 255, 255, 0.87)"}}/>}
                        data={this.props.apps.all ? this.props.apps.all.data.map(a => ({id: a.appId, name: a.name})) : []}
                        onChange={item => {
                            this.props.history.push(`/${env}/apps/${item.id}`);
                            const selectedTab = getPath(this.props, "ui.tabs.selected");
                            if (selectedTab > 0) {
                                this.props.uiTabsSetSelected(0)
                            }
                            else {
                                this.init()
                            }

                        }}/>
                </div>
            )}/>

            <NavbarL3 disableAll={this.state.status !== "ready" || this.props.apps.selected.status !== "ready"}/>

            <div className={`container${[2, 3, 4].includes(this.props.ui.tabs.selected) ? ' full' : [5].includes(this.props.ui.tabs.selected) ? ' mid' : ''}`}>
                {
                    ["ready", "recalc", "wait", "w-init", "e-init"].indexOf(this.state.status) < 0
                        ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
                        : this.renderTabContent()
                }
            </div>
            {['ready', 'wait', 'w-init', 'e-init'].includes(this.state.status) ? (
                <Dialogs
                    muiTheme={this.props.muiTheme}
                    state={this.state}
                    onSetState={this.onSetState}
                    initialize={() => {
                        this.init()
                    }} />
            ) : null}
            <br/>
        </div>;
    }

    private renderTabContent = () => {
        const contentProps = {
            ...this.props,
            state: this.state,
            initialize: () => {
                this.init()
            },
            onSetState: this.onSetState
        };

        switch (this.props.ui.tabs.selected) {
            case 0:
                return <Details {...contentProps} />;
            case 1: return (
                <Activations
                    initialize={() => {
                        this.init()
                    }}
                    parentState={this.state as any}
                    onSetParentState={this.onSetState}
                />
            )
            case 2:
                return <Usage {...contentProps} />;
            case 3:
                return <LoggingTab {...contentProps} type="app" appId={this.state.data.appId} refresh/>;
            case 4:
                return <SessionInspector parentState={this.state} />
            case 5:
                return <Launches {...contentProps} parentState={this.state} />
            default:
                return null;
        }
    };

    private onSetState = (state, cb) => this.setState(state, cb);

    private init = () => {
        this.setState({ status: 'init' })
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));

