import { useState } from "react";
import * as PropTypes from "prop-types";
import axiosWrapper from "../../../lib/axiosWrapper";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as actions from "../../../redux/actions/";

import * as features from "../../../lib/feature-helpers";

import withLegacyTheme from "../../../lib/hoc/with-legacy-theme";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import HelpIcon from "@material-ui/icons/Help";

const LoggedInMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const accountName = props.accounts?.selected?.data?.name || "";
    const accountPath = props.accounts?.selected?.data?.path || "";
    const userName = `${props.login?.data?.first_name} ${props.login?.data?.last_name}`;
    const featureSkipTos = features.check(":SKIP_TOS:", props.features.data);

    return <div data-qa="user-menu" className="userMenu" style={{width: "288px"}}>
        <List
            disablePadding
            style={{
                padding: '0 14px 0 0',
                margin: '8px 0',
            }}
        >
            <ListItem
                data-qa="logged-in-menu"
                style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", padding: 0, cursor: "pointer" }}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                }}
            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <div style={{lineHeight: "24px"}}>{accountName}</div>
                    <ArrowDropDownIcon style={{ color: "#fff", opacity: "0.67" }}/>
                </div>
                <div style={{ height: "14px", marginTop: 4, textAlign: "right", lineHeight: "14px", fontSize: "14px" }}>
                    {userName}
                </div>
            </ListItem>
        </List>

        <Popover
            id="logged-in-menu"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{ style: { width: 288, paddingTop: 8, borderRadius: 8 } }}
        >
            <ListItem data-qa="user-menu-user-details">
                <ListItemAvatar>
                    <Avatar><AccountCircleIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary={userName} secondary={props.login?.data?.email} />
            </ListItem>
            <Button
                data-qa="edit-profile-button"
                style={{ width: 186, height: 24, margin: "12px 51px 16px", border: "1px solid #00567D", borderRadius: 4, color: props.muiTheme.palette.primary1Color, fontFamily: props.muiTheme.fontFamily, fontSize: 12, fontWeight: 500, lineHeight: "24px" }}
                onClick={() => props.history.push("/users/current")}
            >
                Edit Profile
            </Button>
            <Divider />
            <ListItem
                data-qa="user-menu-account-details"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.push("/home?settings=true")}
            >
                <ListItemAvatar>
                    <Avatar><HomeIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary={accountName} secondary={`${accountPath}.interopio.com`} />
            </ListItem>
            <Divider />
            <ListItem
                style={{ cursor: "pointer", color: props.muiTheme.palette.secondaryTextColor }}
                onClick={() => {
                    const url = "https://support.interopio.com/hc/en-us";
                    window.open(url, "_blank");
                }}
            >
                <ListItemAvatar>
                    <Avatar><HelpIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary="Support Center" />
            </ListItem>
            {!featureSkipTos ? <>
                <Divider />
                <div>
                    <Button
                        data-qa="sign-out-button"
                        style={{ width: 186, height: 24, margin: "12px 51px 16px", border: "1px solid #00567D", borderRadius: 12, color: props.muiTheme.palette.primary1Color, fontFamily: props.muiTheme.fontFamily, fontSize: 12, fontWeight: 500, lineHeight: "24px" }}
                        onClick={() => {
                            axiosWrapper(
                                props.config.accountApi,
                                "logout",
                                "POST"
                            )
                            props.performLogout()
                        }}
                    >
                        Sign Out
                    </Button>
                </div>
                {/* <Divider />
                <div style={{ width: 186, margin: "16px 51px 16px", color: props.muiTheme.palette.secondaryTextColor, fontFamily: props.muiTheme.fontFamily, fontSize: 12, textAlign: "center" }}>
                    <a data-qa="user-menu-privacy-button" style={{ cursor: "pointer" }} onClick={() => props.history.push("/policies/privacy-notice")}>
                        Privacy Notice
                    </a>
                    &nbsp;·&nbsp;
                    <a data-qa="user-menu-terms-button" style={{ cursor: "pointer" }} onClick={() => props.history.push("/terms/user-terms-of-service")}>
                        Terms of Service
                    </a>
                </div> */}
            </> : null}
        </Popover>
    </div>;
};

LoggedInMenu.propTypes = {
    config: PropTypes.object.isRequired,
    accounts: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    performLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    accounts: state.accounts,
    features: state.features,
    login: state.login,
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    performLogout: actions.loginReset,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(LoggedInMenu)));
