import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import colors from "../../../../../lib/legacy-color-palette";
import axiosWrapper from "../../../../../lib/axiosWrapper";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import consoleLogger from "../../../../../lib/consoleLogger";
import * as actions from "../../../../../redux/actions/";
import {Divider, Toolbar, MenuItem, Select, Checkbox, Switch, Card, Snackbar, Menu, IconButton, FormGroup, FormHelperText, FormControlLabel, Tooltip, Typography} from "@material-ui/core";
import {MoreVert, Unarchive, DateRange, Refresh, Help} from "@material-ui/icons";
import AppLaunchesOT from "./UsageGraphs/AppLaunchesOT"
import TopResourcesHorizontal from "./UsageGraphs/TopResourcesHorizontal";
import StackedAreaLaunchTime from "./UsageGraphs/StackedAreaLaunchTime";
import ChipDropDown from "../../../../Widgets/ChipDropDown";
import {getDateDisplay, getDateWithZoneDisplay, getFullDateDisplay} from "../../../../../lib/utils";
import getEnvironmentName from "../../../../../lib//getEnvironmentName";
import TotalCount from "../../../../Widgets/Editor/UsageTab/TotalCount";
import LabeledSwitch from "../../../../Widgets/LabeledSwitch";
import "./style.less";

let moment = require("moment-timezone");

const colorQueue = [
    colors.deepPurple600,
    colors.teal600,
    colors.amber600,
    colors.blue600,
    colors.brown600,
    colors.deepOrange600,
    colors.green600,
    colors.red600,
    colors.yellow600,
    colors.lime600
];

export class Analytics extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            assignedColors: {},
            topResourcesByFilter: {
                data: null,
                refreshing: false
            },
            topResourcesTotal: {
                data: null,
                refreshing: false
            },
            launchTimeOverTimeByFilter: {
                data: null,
                refreshing: false
            },
            launchTimeOverTimeTotal: {
                data: null,
                refreshing: false
            },
            launchesOverTimeByFilter: {
                data: null,
                refreshing: false
            },
            launchesOverTimeTotal: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeByFilter: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            totalTransactions: {
                data: null,
                refreshing: false
            },
            totalLaunches: {
                data: null,
                refreshing: false
            },
            timeframe: 2678400000,
            timeframeValue: 2678400000,
            startTime: 0,
            endTime: 0,
            startOf: "day",
            interval: "day",
            allActivations: this.props.apps.selected.data.activations || [],
            visibleActivations: [],
            filteredActivations: [],
            allPractices: [],
            visiblePractices: [],
            filteredPractices: [],
            showMenu: false,
            snackbar: {
                open: false,
                message: "Downloading CSV...",
                autoHideDuration: null
            },
            showLaunchesOverTime: true,
            filterType: "activations"
        };
    }

    public componentDidMount() {
        this.setState({
            endTime: new Date().getTime(),
            allActivations: this.props.apps.selected.data.activations || []
        }, () => {
            this.setAllPractices();
            this.setVisibleActivations();
            this.assignChartColors();
            this.init();
        });
    }

    private setAllPractices() {
        const {accountId} = getHostnameInfo();
        const query = {
            clientId: this.props.apps.selected.data.clientId,
            environmentId: this.props.apps.selected.data.environmentId,
            accountId
        }

        this.runAnalyticsQuery("all-practices", query).then(res => {
            let practices = res.data.aggregations.unique_practice_ids.buckets.map(bucket => {
                return {
                    id: bucket.key,
                    name: bucket.unique_practice_names.buckets[0]?.key || bucket.key
                };
            });
            let vp = [];
            practices.forEach((practice) => {
                if (practice.name) {
                    vp.push(practice.name);
                } else {
                    vp.push(practice.id);
                }
            });
            this.setState({allPractices: practices, visiblePractices: vp, filteredPractices: vp}, () => this.assignChartColors());
        });

    }

    public render() {
        const styles = {
            cardChart: {
                display: "inline-block",
                margin: "5px"
            },
            cardChartText: {padding: 0},
            chartMargins: {top: 15, right: 30, bottom: 5, left: 0},
            subTitle: {margin: 0},
            title: {marginBottom: 0}
        };

        return <div style={{width: "100%"}}>
            <div className="app-analytics">
                {this.renderToolbar()}
                <TotalCount items={{
                    totalLaunches: {...this.state.totalLaunches, displayName: "App Launches"},
                    totalTransactions: {...this.state.totalTransactions, displayName: "FHIR Transactions"}
                }}/>
                <div style={{margin: "30px 10px 10px"}}>Comparing by {this.state.filterType === "practices" ? "Practice" : "Activation"}</div>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.launchesOverTimeByFilter_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.launchTimeOverTimeByFilter_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.topResourcesByFilter_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.transactionsOverTimeByFilter_render(styles)}
                </Card>
                <div style={{margin: "30px 10px 10px"}}>Total Count</div>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.launchesOverTimeTotal_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.launchTimeOverTimeTotal_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.topResourcesTotal_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.transactionsOverTimeTotal_render(styles)}
                </Card>
                <br/>
                <br/>
                <Divider/>
                <Snackbar open={this.state.snackbar.open} message={this.state.snackbar.message} autoHideDuration={this.state.snackbar.autoHideDuration}
                    style={{backgroundColor: this.props.ui.xtheme.palette.colorBlueDark, textAlign: "center"}}/>
            </div>
        </div>;
    }

    private init() {
        Promise.all([this.topResources_init(), this.launchesOverTime_init(), this.transactionsOverTime_init(), this.launchTimeOverTime_init(), this.launchTimeOverTimeTotal_init()])
            .then(([topData, lotData, trotData, ltotData, ltotTotalData]) => {
                this.setState({
                    topResourcesByFilter: {
                        data: this.topResourcesByFilter_postProcess(topData),
                        refreshing: false
                    },
                    topResourcesTotal: {
                        data: this.topResourcesTotal_postProcess(topData),
                        refreshing: false
                    },
                    launchTimeOverTimeByFilter: {
                        data: this.launchTimeOverTimeByFilter_postProcess(ltotData),
                        refreshing: false
                    },
                    launchTimeOverTimeTotal: {
                        data: this.launchTimeOverTimeTotal_postProcess(ltotTotalData),
                        refreshing: false
                    },
                    launchesOverTimeByFilter: {
                        data: this.launchesOverTimeByFilter_postProcess(lotData),
                        refreshing: false
                    },
                    launchesOverTimeTotal: {
                        data: this.launchesOverTimeTotal_postProcess(lotData),
                        refreshing: false
                    },
                    totalLaunches: {
                        data: this.calculateTotalLaunches(lotData),
                        refreshing: false
                    },
                    transactionsOverTimeByFilter: {
                        data: this.transactionsOverTimeByFilter_postProcess(trotData),
                        refreshing: false
                    },
                    transactionsOverTimeTotal: {
                        data: this.transactionsOverTimeTotal_postProcess(trotData),
                        refreshing: false
                    },
                    totalTransactions: {
                        data: this.calculateTotalTransactions(trotData),
                        refreshing: false
                    }
                });
            });
    }

    private refreshAll = () => {
        this.topResources_refresh();
        this.launchesOverTime_refresh();
        this.transactionsOverTime_refresh();
        this.launchTimeOverTimeByFilter_refresh();
        this.launchTimeOverTimeTotal_refresh();
        this.init();
    }

    private handleFilterTypeChange = (event) => {
        let va = [];
        this.state.allActivations.forEach(activation => {
            va.push(activation.name);
        })
        let vp = [];
        this.state.allPractices.forEach(practice => {
            vp.push(practice.name);
        })
        this.setState({
            filterType: event.target.checked ? 'practices' : 'activations',
            visibleActivation: va,
            filteredActivations: va,
            visiblePractices: vp,
            filteredPractices: vp
        }, () => {
            this.refreshAll()
        });
    }

    private setVisibleActivations() {
        let va = [];
        this.state.allActivations.forEach((activation) => {
            va.push(activation.name);
        });
        this.setState({visibleActivations: va, filteredActivations: va});
    }

    private assignChartColors() {
        let assignedColors = {
            "total": colors.indigo600
        };
        for (let x = 0; x < this.state.allActivations.length; x++) {
            let colorIndex = x;
            if (colorIndex > colorQueue.length) {
                colorIndex = colorIndex % colorQueue.length;
            }

            assignedColors[this.state.allActivations[x].name] = colorQueue[colorIndex];
        }
        for (let x = 0; x < this.state.allPractices.length; x++) {
            let colorIndex = x;
            if (colorIndex > colorQueue.length) {
                colorIndex = colorIndex % colorQueue.length;
            }

            assignedColors[this.state.allPractices[x].name] = colorQueue[colorIndex];
        }
        this.setState({assignedColors});
    }

    private getChartStartTimeInMillis() {
        let midnight = moment.tz(this.state.endTime - this.state.timeframe, this.props.ui.timeZone).startOf(this.state.startOf);
        this.setState({startTime: new Date(midnight.format()).getTime()});
        return new Date(midnight.format()).getTime();
    }

    private runAnalyticsQuery(dataType, query) {
        return axiosWrapper(this.props.config.analyticsService, dataType, "POST", query)
    }

    private getTimeZone() {
        return this.props.ui.timeZone
    }

    private topResources_init = () => {
        let query = this.topResources_preProcess();
        return this.runAnalyticsQuery("app-top-data", query).then(res => res.data);
    }

    private topResources_preProcess() {
        const {accountId} = getHostnameInfo();

        return {
            clientId: this.props.apps.selected.data.clientId,
            endTime: new Date().getTime(),
            startTime: this.getChartStartTimeInMillis(),
            environmentId: this.props.apps.selected.data.environmentId,
            accountId,
            filterType: this.state.filterType
        }
    }

    private topResourcesByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        if (this.state.filterType === "practices") {
            for (let i = 0; i < this.state.allPractices.length; i++) {
                let curPractice = this.state.allPractices[i];
                preparedData.series.push(curPractice.name);
            }
        } else {
            for (let i = 0; i < this.state.allActivations.length; i++) {
                let curActivation = this.state.allActivations[i];
                preparedData.series.push(curActivation.name);
            }
        }

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;

        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            const envBuckets = curBucket.group_by_gtw.buckets;

            for (let j = 0; j < envBuckets.length; j++) {
                let curEnvBucket = envBuckets[j];
                let gtwKey = curEnvBucket.key
                let activationKey;
                if (this.state.filterType === "practices") {
                    for (let practice of this.state.allPractices){
                        if (gtwKey === practice.id) {
                            activationKey = practice.name
                            curData[activationKey] = curEnvBucket.doc_count;
                        }
                    }
                } else {
                    for (let activation of this.state.allActivations) {
                        if (gtwKey === activation.gatewayId) {
                            activationKey = activation.name
                            // Change this after session_type starts getting populated in ES
                            if (activation.smartLaunchProxyInfo2Api) {
                                curData[activationKey] = 0;
                            } else {
                                curData[activationKey] = curEnvBucket.doc_count;
                            }
                        }
                    }
                }
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                datapoint["name"] = resources[i];
                datapoint[this.props.apps.selected.data.name] = 0;
                placeholeder.push(datapoint)
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    }

    private topResourcesTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;
        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                datapoint["name"] = resources[i];
                datapoint[this.props.apps.selected.data.name] = 0;
                placeholeder.push(datapoint)
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    }

    private topResources_refresh = () => {
        this.setState({
            topResourcesByFilter: {
                data: this.state.topResourcesByFilter.data,
                refreshing: true
            },
            topResourcesTotal: {
                data: this.state.topResourcesTotal.data,
                refreshing: true
            }
        });
    }

    private topResourcesByFilter_render(styles) {
        return <TopResourcesHorizontal state={this.state} data={this.state.topResourcesByFilter.data} styles={styles} topResources_refresh={this.topResources_refresh} legend={true}
            title={`Resource Transactions By ${this.state.filterType === "practices" ? "Practice" : "Activation"}`}/>
    }

    private topResourcesTotal_render(styles) {
        return <TopResourcesHorizontal state={this.state} data={this.state.topResourcesTotal.data} styles={styles} topResources_refresh={this.topResources_refresh} legend={false}
            title="Resource Transactions - Total"/>
    }


    // LAUNCHTIMEOVERTIME
    private launchTimeOverTime_init = () => {
        let query = this.launchTimeOverTime_preProcess();
        return Promise.all([this.runAnalyticsQuery("app-ltot-smart-launch", query), this.runAnalyticsQuery("app-ltot-app-ready", query), this.runAnalyticsQuery("app-ltot-app-complete", query)])
            .then(([ltotData, ltotData2, ltotData3]) => {
                return [ltotData.data, ltotData2.data, ltotData3.data]
            }).catch(() => {
                this.setState({showLaunchesOverTime: false});
                return [{ aggregations: { date: { buckets: []}}}, [], []]
            });
    }

    private launchTimeOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }
        let gateways = [];
        this.props.gateways.all.data.forEach((gateway) => {
            gateways.push(gateway.gatewayId);
        })
        let practices = [];
        this.state.allPractices.forEach((practice) => {
            practices.push(practice.id);
        })

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            clientId: this.props.apps.selected.data.clientId,
            gateways,
            practices,
            accountId: this.props.apps.selected.data.accountId,
            environmentId: this.props.apps.selected.data.environmentId,
            filterType: this.state.filterType,
        }

        return query;
    }

    private launchTimeOverTimeByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        if (this.state.filterType === "practices") {
            for (let i = 0; i < this.state.allPractices.length; i++) {
                let curPractice = this.state.allPractices[i];
                preparedData.series.push(curPractice.name);
            }
        } else {
            for (let i = 0; i < this.state.allActivations.length; i++) {
                let curActivation = this.state.allActivations[i];
                preparedData.series.push(curActivation.name);
            }
        }

        const buckets = rawData[0].aggregations["date"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["gateways"]["buckets"]) {
                let gtwKey = key.split(":")[1];
                let activationKey;
                if (this.state.filterType === "practices") {
                    for (let practice of this.state.allPractices){
                        if (gtwKey === practice.id) {
                            activationKey = practice.name
                        }
                    }
                } else {
                    for (let activation of this.state.allActivations) {
                        if (gtwKey === activation.gatewayId) {
                            activationKey = activation.name
                        }
                    }
                }
                curData[activationKey + "_SMART_launch_avg"] = curBucket["gateways"]["buckets"][key].SMART_launch_avg.value;
                curData[activationKey + "_SMART_launch_med"] = curBucket["gateways"]["buckets"][key].SMART_launch_median.values["50.0"];
                curData[activationKey + "_app_ready_avg"] = rawData[1].aggregations["date"].buckets[i]["gateways"]["buckets"][key].app_ready_avg.value;
                curData[activationKey + "_app_ready_med"] = rawData[1].aggregations["date"].buckets[i]["gateways"]["buckets"][key].app_ready_median.values["50.0"];
                curData[activationKey + "_app_launch_comp_avg"] = rawData[2].aggregations["date"].buckets[i]["gateways"]["buckets"][key].app_launch_comp_avg.value;
                curData[activationKey + "_app_launch_comp_med"] = rawData[2].aggregations["date"].buckets[i]["gateways"]["buckets"][key].app_launch_comp_median.values["50.0"];
                curData[activationKey + "_numberOfLaunches"] = curBucket["gateways"]["buckets"][key]["session_id"]["buckets"].length;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private launchTimeOverTimeTotal_init = () => {
        let query = this.launchTimeOverTimeTotal_preProcess();
        return Promise.all([this.runAnalyticsQuery("app-ltot-total-smart-launch", query), this.runAnalyticsQuery("app-ltot-total-app-ready", query), this.runAnalyticsQuery("app-ltot-total-app-complete", query)])
            .then(([ltotData, ltotData2, ltotData3]) => {
                return [ltotData.data, ltotData2.data, ltotData3.data]
            }).catch(() => {
                this.setState({showLaunchesOverTime: false});
                return [{aggregations: {date: { buckets: []}}}, {}, {}]
            });
    }

    private launchTimeOverTimeTotal_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }
        let gateways = this.props.apps.selected.data.activations.filter(act => this.state.filteredActivations.indexOf(act.name) > -1).map(({ gatewayId }) => gatewayId)

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            clientId: this.props.apps.selected.data.clientId,
            gateways,
            accountId: this.props.apps.selected.data.accountId,
            environmentId: this.props.apps.selected.data.environmentId
        }

        return query;
    }

    private launchTimeOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData[0].aggregations["date"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total_SMART_launch_avg"] = curBucket.SMART_launch_avg.value;
            curData["total_SMART_launch_med"] = curBucket.SMART_launch_median.values["50.0"];
            curData["total_app_ready_avg"] = rawData[1].aggregations["date"].buckets[i].app_ready_avg.value;
            curData["total_app_ready_med"] = rawData[1].aggregations["date"].buckets[i].app_ready_median.values["50.0"];
            curData["total_app_launch_comp_avg"] = rawData[2].aggregations["date"].buckets[i].app_launch_comp_avg.value;
            curData["total_app_launch_comp_med"] = rawData[2].aggregations["date"].buckets[i].app_launch_comp_median.values["50.0"];
            curData["total_numberOfLaunches"] = curBucket["session_id"]["buckets"].length;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            let intervals;
            let add;
            let datapointName = this.getChartStartTimeInMillis();
            switch (this.state.timeframe) {
                case 0:
                    intervals = Math.floor((new Date().getTime() - datapointName) / 3600000);
                    add = "hour";
                    break;
                case 86400000:
                    intervals = 24;
                    add = "hour";
                    break;
                case 604800000:
                    intervals = 7;
                    add = "day";
                    break;
                case 2678400000:
                    intervals = 30;
                    add = "day";
                    break;
                case 8035200000:
                    intervals = 14;
                    add = "week";
                    break;
                case 31536000000:
                    intervals = 12;
                    add = "month";
                    break;
            }
            for (let i = 0; i <= intervals; i++) {
                let datapoint = {};
                datapoint["name"] = datapointName;
                datapoint[this.props.apps.selected.data.name] = 0;
                placeholeder.push(datapoint);
                datapointName = new Date(moment.tz(datapointName, this.props.ui.timeZone).add(1, add).format()).getTime();
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    }

    private launchTimeOverTimeByFilter_refresh = () => {
        this.setState({
            launchTimeOverTimeByFilter: {
                data: this.state.launchTimeOverTimeByFilter.data,
                refreshing: true
            }
        });
    }

    private launchTimeOverTimeTotal_refresh = () => {
        this.setState({
            launchesOverTimeTotal: {
                data: this.state.launchTimeOverTimeTotal.data,
                refreshing: true
            },
        });
    }

    private launchTimeOverTimeByFilter_render(styles) {
        return <StackedAreaLaunchTime ui={this.props.ui} state={this.state} data={this.state.launchTimeOverTimeByFilter.data} styles={styles} launchesOverTime_refresh={this.launchTimeOverTimeByFilter_refresh}
            title={`Time By ${this.state.filterType === "practices" ? "Practice" : "Activation"}`} selector={true} showLaunchesOverTime={this.state.showLaunchesOverTime} />
    }

    private launchTimeOverTimeTotal_render(styles) {
        return <StackedAreaLaunchTime ui={this.props.ui} state={this.state} data={this.state.launchTimeOverTimeTotal.data} styles={styles} launchesOverTime_refresh={this.launchTimeOverTimeTotal_refresh}
            legend={false} title="Time Total" selector={true} showLaunchesOverTime={this.state.showLaunchesOverTime}/>
    }


    // Launches Over Time
    private launchesOverTime_init = () => {
        let query = this.launchesOverTime_preProcess();
        return this.runAnalyticsQuery("app-lot-data", query).then(res => res.data);
    }

    private launchesOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }
        let gateways = [];
        this.props.gateways.all.data.forEach((gateway) => {
            gateways.push(gateway.gatewayId);
        })
        let practices = [];
        this.state.allPractices.forEach((practice) => {
            practices.push(practice.id);
        })

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            clientId: this.props.apps.selected.data.clientId,
            gateways,
            practices,
            filterType: this.state.filterType,
            accountId: this.props.apps.selected.data.accountId,
            environmentId: this.props.apps.selected.data.environmentId
        }

        return query;
    }

    private launchesOverTimeByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        if (this.state.filterType === "practices") {
            for (let i = 0; i < this.state.allPractices.length; i++) {
                let curPractice = this.state.allPractices[i];
                preparedData.series.push(curPractice.name);
            }
        } else {
            for (let i = 0; i < this.state.allActivations.length; i++) {
                let curActivation = this.state.allActivations[i];
                preparedData.series.push(curActivation.name);
            }
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            if (this.state.filterType === "practices") {
                for (let key in curBucket["3"]["buckets"]) {
                    let gtwKey = key.split(":")[1];
                    let activationKey;
                    for (let practice of this.state.allPractices) {
                        if (gtwKey === practice.id) {
                            activationKey = practice.name
                            curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
                        }
                    }
                }
            } else {
                for (let key in curBucket["3"]["buckets"]) {
                    let gtwKey = key.split(":")[1];
                    let activationKey;
                    for (let activation of this.state.allActivations) {
                        if (gtwKey === activation.gatewayId) {
                            activationKey = activation.name
                            // Change this after session_type starts getting populated in ES
                            if (activation.smartLaunchProxyInfo2Api) {
                                curData[activationKey] = 0;
                            } else {
                                curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
                            }
                        }
                    }
                }
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private launchesOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            let intervals;
            let add;
            let datapointName = this.getChartStartTimeInMillis();
            switch (this.state.timeframe) {
                case 0:
                    intervals = Math.floor((new Date().getTime() - datapointName) / 3600000);
                    add = "hour";
                    break;
                case 86400000:
                    intervals = 24;
                    add = "hour";
                    break;
                case 604800000:
                    intervals = 7;
                    add = "day";
                    break;
                case 2678400000:
                    intervals = 30;
                    add = "day";
                    break;
                case 8035200000:
                    intervals = 14;
                    add = "week";
                    break;
                case 31536000000:
                    intervals = 12;
                    add = "month";
                    break;
            }
            for (let i = 0; i <= intervals; i++) {
                let datapoint = {};
                datapoint["name"] = datapointName;
                datapoint[this.props.apps.selected.data.name] = 0;
                placeholeder.push(datapoint);
                datapointName = new Date(moment.tz(datapointName, this.props.ui.timeZone).add(1, add).format()).getTime();
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    }

    private calculateTotalLaunches(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private launchesOverTime_refresh = () => {
        this.setState({
            launchesOverTimeByFilter: {
                data: this.state.launchesOverTimeByFilter.data,
                refreshing: true
            },
            launchesOverTimeTotal: {
                data: this.state.launchesOverTimeTotal.data,
                refreshing: true
            },
            totalLaunches: {
                data: this.state.totalLaunches.data,
                refreshing: true
            }
        });
    }

    private launchesOverTimeByFilter_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.launchesOverTimeByFilter.data} styles={styles} launchesOverTime_refresh={this.launchesOverTime_refresh}
            title={`App Launches By ${this.state.filterType === "practices" ? "Practice" : "Activation"}`}/>
    }

    private launchesOverTimeTotal_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.launchesOverTimeTotal.data} styles={styles} launchesOverTime_refresh={this.launchesOverTime_refresh}
            legend={false} title="Total App Launches"/>
    }

    private transactionsOverTime_init() {
        let query = this.transactionsOverTime_preProcess();
        return this.runAnalyticsQuery("app-trot-data", query).then(res => res.data);
    }

    private transactionsOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }
        let gateways = [];
        this.props.gateways.all.data.forEach((gateway) => {
            gateways.push(gateway.gatewayId);
        });
        let practices = [];
        this.state.allPractices.forEach((practice) => {
            practices.push(practice.id);
        })

        return {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            clientId: this.props.apps.selected.data.clientId,
            gateways,
            practices,
            filterType: this.state.filterType,
            accountId: this.props.apps.selected.data.accountId,
            environmentId: this.props.apps.selected.data.environmentId
        };
    }

    private transactionsOverTimeByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        if (this.state.filterType === "practices") {
            for (let i = 0; i < this.state.allPractices.length; i++) {
                let curPractice = this.state.allPractices[i];
                preparedData.series.push(curPractice.name);
            }
        } else {
            for (let i = 0; i < this.state.allActivations.length; i++) {
                let curActivation = this.state.allActivations[i];
                preparedData.series.push(curActivation.name);
            }
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            if (this.state.filterType === "practices") {
                for (let key in curBucket["3"]["buckets"]) {
                    let gtwKey = key.split(":")[1];
                    let activationKey;
                    for (let practice of this.state.allPractices) {
                        if (gtwKey === practice.id) {
                            activationKey = practice.name
                            curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
                        }
                    }
                }
            } else {
                for (let key in curBucket["3"]["buckets"]) {
                    let gtwKey = key.split(":")[1];
                    let activationKey;
                    for (let activation of this.state.allActivations) {
                        if (gtwKey === activation.gatewayId) {
                            activationKey = activation.name
                            // Change this after session_type starts getting populated in ES
                            if (activation.smartLaunchProxyInfo2Api) {
                                curData[activationKey] = 0;
                            } else {
                                curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
                            }
                        }
                    }
                }
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private transactionsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            let intervals;
            let add;
            let datapointName = this.getChartStartTimeInMillis()
            switch (this.state.timeframe) {
                case 0:
                    intervals = Math.floor((new Date().getTime() - datapointName) / 3600000)
                    add = "hour"
                    break;
                case 86400000:
                    intervals = 24
                    add = "hour"
                    break;
                case 604800000:
                    intervals = 7
                    add = "day"
                    break;
                case 2678400000:
                    intervals = 30
                    add = "day"
                    break;
                case 8035200000:
                    intervals = 14
                    add = "week"
                    break;
                case 31536000000:
                    intervals = 12
                    add = "month"
                    break;
            }
            for (let i = 0; i <= intervals; i++) {
                let datapoint = {};
                datapoint["name"] = datapointName;
                datapoint[this.props.apps.selected.data.name] = 0;
                placeholeder.push(datapoint);
                datapointName = new Date(moment.tz(datapointName, this.props.ui.timeZone).add(1, add).format()).getTime();
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    }

    private calculateTotalTransactions(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private transactionsOverTime_refresh() {
        this.setState({
            transactionsOverTimeByFilter: {
                data: this.state.transactionsOverTimeByFilter.data,
                refreshing: true
            },
            transactionsOverTimeTotal: {
                data: this.state.transactionsOverTimeTotal.data,
                refreshing: true
            },
            totalTransactions: {
                data: this.state.totalTransactions.data,
                refreshing: true
            }
        });
    }

    private transactionsOverTimeByFilter_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.transactionsOverTimeByFilter.data} styles={styles}
            launchesOverTime_refresh={this.transactionsOverTime_refresh}
            title={`App Transactions By ${this.state.filterType === "practices" ? "Practice" : "Activation"}`}/>
    }

    private transactionsOverTimeTotal_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.transactionsOverTimeTotal.data} styles={styles}
            launchesOverTime_refresh={this.transactionsOverTime_refresh} legend={false}
            title="Total App Transactions"/>
    }

    private renderToolbar = () => {
        let timeframe = getDateDisplay(this.state.startTime, this.props.ui.timeZone) + " - " + getDateWithZoneDisplay(this.state.endTime, this.props.ui.timeZone);
        let labelActivations = "Activations";
        if (this.state.filteredActivations.length && this.state.filteredActivations.length !== this.state.allActivations.length) {
            labelActivations = this.state.filteredActivations[0];
            if (this.state.filteredActivations.length > 1) {
                labelActivations += ` + ${this.state.filteredActivations.length - 1}`;
            }
        } else if (this.state.filteredActivations.length === 0) {
            labelActivations = "Activations";
        }

        let labelPractices = "Practices";
        if (this.state.filteredPractices.length && this.state.filteredPractices.length !== this.state.allPractices.length) {
            labelPractices = this.state.filteredPractices[0];
            if (this.state.filteredPractices.length > 1) {
                labelPractices += ` + ${this.state.filteredPractices.length - 1}`;
            }
        } else if (this.state.filteredPractices.length === 0) {
            labelPractices = "Practices";
        }

        return <Toolbar className={"analytics-toolbar"} style={{height: "76px", backgroundColor: "rgb(232, 232, 232)", display: "flex", justifyContent: "space-between"}}>
            <div>
                <DateRange style={{fontSize: "24px", paddingRight: "10px", alignSelf: "center", position: "relative", top: "10px"}}/>
                <Select value={this.state.timeframeValue} onChange={this.handleTimeSpanChange}>
                    <MenuItem value={0}>Today</MenuItem>
                    <MenuItem value={86400000}>Yesterday</MenuItem>
                    <MenuItem value={604800000}>Last 7 days</MenuItem>
                    <MenuItem value={2678400000}>Last 30 days</MenuItem>
                    <MenuItem value={8035200000}>Last 90 days</MenuItem>
                    <MenuItem value={31536000000}>Last 365 days</MenuItem>
                    <MenuItem value={-12}>Year-to-date</MenuItem>
                </Select>
                <FormHelperText>{timeframe}</FormHelperText>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <FormGroup style={{alignSelf: "center", marginRight: 20}}>
                    <Typography variant="body2" align="center" color="textSecondary">Filter By:</Typography>
                    <LabeledSwitch disabled={!this.state.allPractices.length} checked={this.state.filterType === 'practices'} onChange={this.handleFilterTypeChange} labelOff='Activations' labelOn="Practices"/>
                </FormGroup>
                <div style={{alignSelf: "center"}}>
                    <ChipDropDown
                        label={this.state.filterType === 'practices' ? labelPractices : labelActivations}
                        inactiveBackground="#fff"
                        inactiveColor={this.props.muiTheme.palette.textColor}
                        activeBackground={this.props.muiTheme.palette.primary1Color}
                        activeColor="#fff"
                        popover={{
                            title: this.state.filterType === 'practices' ? "Practices" : "Activations",
                            content: <div style={{margin: "8px 16px"}}>
                                <FormControlLabel label={this.state.filterType === 'practices' ? "Select All Practices" : "Select All Activations"} style={{marginBottom: "8px"}}
                                    control={<Switch color="primary" checked={this.state.filterType === 'practices' ? this.state.filteredPractices.length === this.state.allPractices.length : this.state.filteredActivations.length === this.state.allActivations.length}
                                        onChange={this.handleToggle}/>}/>
                                <Divider style={{margin: "8px 0px"}}/>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    {(this.state.filterType === 'practices' ? this.state.allPractices : this.state.allActivations).sort((a, b) => a.name.localeCompare(b.name)).map(item => {
                                        let index = (this.state.filterType === 'practices' ? this.state.filteredPractices : this.state.filteredActivations).indexOf(item.name);
                                        return <FormControlLabel key={item.name} label={item.name} control={
                                            <Checkbox color="primary" key={`${this.state.filterType}-${item.name}`} name={`${this.state.filterType}-${item.name}`} checked={index >= 0}
                                                onChange={e => {
                                                    let isChecked = e.target.checked;
                                                    let vi = $.extend(true, [], this.state.filterType === 'practices' ? this.state.filteredPractices : this.state.filteredActivations);
                                                    if (isChecked && index < 0) {
                                                        vi.push(item.name)
                                                    } else if (!isChecked && index >= 0) {
                                                        vi.splice(index, 1);
                                                    }
                                                    this.handleVisibleItemsChange(vi, this.state.filterType);
                                                }}/>
                                        }/>
                                    })}
                                </div>
                            </div>
                        }}
                        isActive={this.isFilterResourcesActive()}
                        onRequestDelete={() => {
                            let va = [];
                            (this.state.filterType === 'practices' ? this.state.allPractices : this.state.allActivations).forEach((item) => {
                                va.push(item.name);
                            });
                            this.handleVisibleItemsChange(va, this.state.filterType);
                        }}
                    />
                </div>
                <Tooltip title="Refresh" placement="bottom">
                    <IconButton onClick={this.refreshAll} disabled={this.state.topResourcesByFilter.refreshing} disableTouchRipple style={{alignSelf: "center", margin: "10px 0 10px 10px"}}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
                <IconButton
                    style={{width: "48px", height: "48px", top: "10px"}}
                    onClick={() => {
                        const url = "https://support.interopio.com/hc/en-us/articles/360030866572";
                        window.open(url, "_blank");
                    }}
                >
                    <Help/>
                </IconButton>
                <IconButton style={{width: "48px", height: "48px", top: "10px"}} onClick={this.toggleMenu}>
                    <MoreVert/>
                </IconButton>
                <Menu open={this.state.showMenu} className="right-icon" anchorEl={this.state.anchorEl} onClose={this.toggleMenu}>
                    <MenuItem key="right-menu-export-transactions" onClick={() => this.exportTransactionsCsv()}>
                        <Unarchive style={{fill: "rgb(117, 117, 117)"}}/> <span style={{padding: "8px 24px"}}>Export App Transactions as CSV</span>
                    </MenuItem>
                    <MenuItem key="right-menu-export-launches" onClick={() => this.exportLaunchesCsv()}>
                        <Unarchive style={{fill: "rgb(117, 117, 117)"}}/> <span style={{padding: "8px 24px"}}>Export App Launches as CSV</span>
                    </MenuItem>
                </Menu>
            </div>
        </Toolbar>;
    };

    toggleMenu = (e) => {
        this.setState({anchorEl: e.currentTarget, showMenu: !this.state.showMenu});
    }

    private handleVisibleItemsChange = (visibleItems, type) => {
        let vi = $.extend(true, [], visibleItems);
        let lot = $.extend(true, {}, this.state.launchesOverTimeByFilter);
        let ltot = $.extend(true, {}, this.state.launchTimeOverTimeByFilter);
        let top = $.extend(true, {}, this.state.topResourcesByFilter);
        let trot = $.extend(true, {}, this.state.transactionsOverTimeByFilter);
        lot.data.series = vi;
        ltot.data.series = vi;
        top.data.series = vi;
        trot.data.series = vi;

        let newState = {
            launchesOverTimeByFilter: lot,
            launchTimeOverTimeByFilter: ltot,
            topResourcesByFilter: top,
            transactionsOverTimeByFilter: trot,
        };

        if (type === 'activations') {
            newState['filteredActivations'] = visibleItems;
            newState['visibleActivations'] = vi;
        } else if (type === 'practices') {
            newState['filteredPractices'] = visibleItems;
            newState['visiblePractices'] = vi;
        }

        this.setState(newState, this.updateTotalValues);
    }

    private updateTotalValues = async () => {
        let totalLaunchTime = this.state.launchTimeOverTimeTotal;
        this.launchTimeOverTimeByFilter_refresh();
        totalLaunchTime = await this.launchTimeOverTimeTotal_init();
        let lotTotal = $.extend(true, {}, this.state.launchesOverTimeTotal);
        let topTotal = $.extend(true, {}, this.state.topResourcesTotal);
        let trotTotal = $.extend(true, {}, this.state.transactionsOverTimeTotal);
        let lotEntries = $.extend(true, [], this.state.launchesOverTimeByFilter.data.entries);
        let topEntries = $.extend(true, [], this.state.topResourcesByFilter.data.entries);
        let trotEntries = $.extend(true, [], this.state.transactionsOverTimeByFilter.data.entries);
        const visibleFilter = this.state.filterType === 'practices' ? this.state.visiblePractices : this.state.visibleActivations;
        let lotData = [];
        let topData = [];
        let trotData = [];
        let totalLaunches = 0;
        let totalTransactions = 0;
        lotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleFilter.forEach(activation => {
                curEntry["total"] += entry[activation];
            })
            totalLaunches += curEntry["total"];
            lotData.push(curEntry);
        });
        lotTotal.data.entries = lotData;

        topEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleFilter.forEach(activation => {
                if (entry[activation]) {
                    curEntry["total"] += entry[activation];
                }
            })
            topData.push(curEntry);
        });
        topData.sort((a, b) => (a["total"] > b["total"]) ? -1 : ((b["total"] > a["total"]) ? 1 : 0))
        topTotal.data.entries = topData;

        trotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleFilter.forEach(activation => {
                curEntry["total"] += entry[activation];
            })
            totalTransactions += curEntry["total"];
            trotData.push(curEntry);
        });
        trotTotal.data.entries = trotData;

        this.setState({
            launchesOverTimeTotal: lotTotal,
            topResourcesTotal: topTotal,
            transactionsOverTimeTotal: trotTotal,
            totalLaunches: {
                data: totalLaunches,
                refreshing: false
            },
            totalTransactions: {
                data: totalTransactions,
                refreshing: false
            },
            launchTimeOverTimeTotal: {
                data: this.launchTimeOverTimeTotal_postProcess(totalLaunchTime),
                refreshing: false
            },
        });
    }

    private handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        let type = this.state.filterType;
        let allArray = 'allActivations'
        if (type === 'practices') {
            allArray = 'allPractices'
        }
        let checked = event.target.checked;
        if (checked) {
            this.handleVisibleItemsChange(this.state[allArray].map(item => item.name), type);
        } else {
            this.handleVisibleItemsChange([], type);
        }
    }

    private isFilterResourcesActive = () => {
        if (this.state.filterType === 'practices') {
            return this.state.filteredPractices.length !== this.state.allPractices.length;
        }
        return this.state.visibleActivations.length !== this.state.allActivations.length;
    }

    private handleTimeSpanChange = (e) => {
        let startOf;
        let interval;
        let value = e.target.value;
        let timeframeValue = value;
        switch (value) {
            case 0:
                startOf = "day"
                interval = "hour"
                break;
            case 86400000:
                startOf = "day"
                interval = "hour"
                break;
            case 604800000:
                startOf = "day"
                interval = "day"
                break;
            case 2678400000:
                startOf = "day"
                interval = "day"
                break;
            case 8035200000:
                startOf = "week"
                interval = "week"
                break;
            case 31536000000:
                startOf = "month"
                interval = "month"
                break;
            case -12:
                value = this.calculateTimeframe("year")
                timeframeValue = -12;
                startOf = "day";
                if (value < 86400000) {
                    interval = "hour"
                } else if (value < 2678400000) {
                    interval = "day"
                } else if (value < 10713600000) {
                    interval = "week"
                } else {
                    interval = "month"
                }
                break;
        }
        this.setState({timeframe: value, timeframeValue, interval, startOf}, this.refreshAll);
    };

    private calculateTimeframe = startOf => {
        var startDate = moment(this.state.endTime).tz(this.props.ui.timeZone).startOf(startOf);
        var endDate = moment(this.state.endTime).tz(this.props.ui.timeZone);
        var timeframe = endDate.diff(startDate);
        return timeframe;
    };

    private exportTransactionsCsv = () => {
        this.setState({
            snackbar: {
                open: true,
                message: "Downloading CSV...",
                autoHideDuration: null
            }
        });
        const endTime = new Date().getTime();
        const startTime = this.state.startTime;
        const accountId = this.props.apps.selected.data.accountId;
        const accountName = this.props.accounts.selected.data.name;
        const environmentId = this.props.apps.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, this.props.environments);
        const clientName = this.props.apps.selected.data.name;
        const query = {
            startTime,
            endTime,
            clientId: this.props.apps.selected.data.clientId,
            clientName,
            accountId,
            environmentId,
            timeZone: this.props.ui.timeZone
        };
        axiosWrapper(this.props.config.analyticsService, "app-export-transactions", "POST", query, {}, "blob")
            .then((response) => {
                let fileName = `${accountName}_${environmentName}_${clientName}_app-transactions-${getFullDateDisplay(Date.now(), this.props.ui.timeZone)}.csv`;
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    (window.navigator as any).msSaveOrOpenBlob(new Blob([response.data]), fileName);
                } else {
                    const blob = new Blob([response.data]);
                    const url = window.URL.createObjectURL(blob);
                    const sanitizedUrl = encodeURI(url);
                    const link = document.createElement("a");
                    link.href = sanitizedUrl;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                this.setState({
                    snackbar: {
                        open: true,
                        message: "CSV Downloaded",
                        autoHideDuration: 4000
                    }
                });
            })
            .catch((error) => {
                consoleLogger.log(error);
                this.setState({
                    snackbar: {
                        open: true,
                        message: "Error Downloading CSV",
                        autoHideDuration: 4000
                    }
                });
            });
    };

    private exportLaunchesCsv = () => {
        this.setState({
            snackbar: {
                open: true,
                message: "Downloading CSV...",
                autoHideDuration: null
            }
        });
        const endTime = new Date().getTime();
        const startTime = this.state.startTime;
        const accountId = this.props.apps.selected.data.accountId;
        const environmentId = this.props.apps.selected.data.environmentId;
        const accountName = this.props.accounts.selected.data.name;
        const environmentName = getEnvironmentName(environmentId, this.props.environments);
        const clientName = this.props.apps.selected.data.name;
        const query = {
            startTime,
            endTime,
            clientId: this.props.apps.selected.data.clientId,
            clientName,
            accountId,
            environmentId,
            timeZone: this.props.ui.timeZone
        }
        axiosWrapper(this.props.config.analyticsService, "app-export-launches", "POST", query, {}, "blob")
            .then((response) => {
                let fileName = `${accountName}_${environmentName}_${clientName}_app-launches-${getFullDateDisplay(Date.now(), this.props.ui.timeZone)}.csv`;
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    (window.navigator as any).msSaveOrOpenBlob(new Blob([response.data]), fileName);
                } else {
                    const blob = new Blob([response.data]);
                    const url = window.URL.createObjectURL(blob);
                    const sanitizedUrl = encodeURI(url);
                    const link = document.createElement("a");
                    link.href = sanitizedUrl;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                this.setState({
                    snackbar: {
                        open: true,
                        message: "CSV Downloaded",
                        autoHideDuration: 4000
                    }
                });
            })
            .catch((error) => {
                consoleLogger.log(error);
                this.setState({
                    snackbar: {
                        open: true,
                        message: "Error Downloading CSV",
                        autoHideDuration: 4000
                    }
                });
            });
    }

}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Analytics));
