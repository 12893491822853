import * as React from "react";
import * as PropTypes from "prop-types";
import getAppsCallUrl from "../../../../../../lib/getAppsCallUrl";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
// import getActiveEnvironment from "../../../../../../lib/getActiveEnvironment";
import consoleLogger from "../../../../../../lib/consoleLogger";
import {Button} from "@material-ui/core";
import {Check, KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";

const uuidv4: Function = require("uuid/v4");

// const activeEnv = getActiveEnvironment()
// const includeAuthV2 = [":LOCAL:", ":DEVELOPMENT:", ":DEV-AZURE:", ":TEST:", ":QA-AZURE:"].includes(activeEnv);

const Component: React.FunctionComponent<any> = props => {

    const content = [
        props.state.wstep > props.WSTEP_MIN
            ? <Button variant="outlined" key="action-btn-back" disabled={props.state.status !== "ready"} style={{marginRight: "8px"}}
                onClick={() => props.onSetState({wstep: props.calcPrevWStep()})}>
                <KeyboardArrowLeft/> Back
            </Button>
            : null,
        props.state.wstep < props.WSTEP_MAX
            ? <Button variant="contained" data-qa="new-app-modal-next" key="action-btn-next" color="primary"
                disabled={!props.state.isNextWStepEnabled || props.state.status !== "ready"} onClick={() => props.onSetState({wstep: props.calcNextWStep()})}>
                Next <KeyboardArrowRight/>
            </Button>
            : null,
        props.state.wstep === props.WSTEP_MAX
            ? <Button variant="contained" data-qa="new-app-modal-finish" key="action-btn-register-app" color="primary" disabled={props.state.status !== "ready"}
                onClick={() => {
                    props.onSetState(
                        {status: "wait"},
                        () => {
                            const {accountId} = getHostnameInfo();
                            const {env} = getLocationInfo(props.location, props.config);
                            const appUrl = getAppsCallUrl(accountId, env) + `/${props.state.data.appId}`;
                            // const isConfidentialClient = props.state.data?.appAuthTypes?.includes("BACKEND_SERVICE");

                            const data = {
                                accountId,
                                environmentId: env,
                                name: props.state.data.name,
                                appId: props.state.data.appId,
                                logViewer: props.state.data.logViewer,
                                description: props.state.data.description,
                                clientId: props.state.data.clientId ? props.state.data.clientId : uuidv4(),
                                launchUrl: props.state.data.launchUrl,
                                healthUrl: props.state.data.healthUrl || "",
                                redirectUrls: props.state.data.redirectUrls,
                                appListings: props.state.data.appListings,
                                scopes: props.state.data.scopes,
                                appAuthTypes: props.state.data.appAuthTypes,
                                clientSecret: props.state.data.clientSecret,
                                clientAuthenticationMethod: props.state.data.clientAuthenticationMethod,
                                requireProofKey: props.state.data.requireProofKey,
                                wellKnownJwksJson: props.state.data.wellKnownJwksJson || null,
                            };
                            props.appsCreate(props.config, appUrl, data)
                                .then(res => {
                                    if (res && res.status < 300 && res.data) {
                                        // eslint-disable-next-line react/prop-types
                                        props.initialize();
                                        const url = `/${env}/apps/${res.data.appId}`;
                                        props.history.push(url);
                                    }
                                })
                                .catch((reason) => consoleLogger.error(":::", reason));
                        }
                    );
                }}
            >
                <Check/> Register App
            </Button>
            : null
    ];

    return <div>{content}</div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    WSTEP_MAX: PropTypes.number.isRequired,
    WSTEP_MIN: PropTypes.number.isRequired,

    appsCreate: PropTypes.func.isRequired,
    calcNextWStep: PropTypes.func.isRequired,
    calcPrevWStep: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
