import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import axiosWrapper from "../../../../../lib/axiosWrapper";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import * as actions from "../../../../../redux/actions/";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import TransactionsTable from "./TransactionsTable";
import AggregatedTransactionsTable from "./AggregatedTransactionsTable";
import { Tabs, Tab, Box } from "@material-ui/core";
import "./style.less";

let moment = require("moment-timezone");
export class Transactions extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            status: "init",
            allActivatedApps: [],
            allTransactions: {
                data: null,
                refreshing: false
            },
            transactions: {
                data: null,
                refreshing: false
            },
            aggregatedTransactions: {
                data: null,
                refreshing: false
            },
            filters: {
                pageSize: 50
            },
            timeframe: 0,
            sortBy: {
                time: true
            },
            aggregateSortBy: {
                count: true
            },
            showAll: false,
            // startDate will be saved in local time, startDateInMs will be saved in timeZone at 00:00;
            startDate: null,
            startDateInMs: null,
            endDate: null,
            endDateInMs: null,
            transactionsPagination: {
                number: 0,
                size: 50,
                totalElements: 0
            },
            resourceFilterValues: [],
            aggregatedData: null, // Add state for aggregated data
            selectedTab: 0, // Add selectedTab state
        };
    }

    public componentDidMount() {
        this.setState({
            allActivatedApps: this.props.gateways.selected.data.activatedApps,
            endDate: new Date(),
            endDateInMs: new Date().getTime(),
            startDate: this.getStartDate(new Date()),
            startDateInMs: this.getStartDate(new Date()).getTime()
        }, () => {
            this.getResourceFilter();
            this.transactions_init();
            if (this.state.selectedTab === 1)
                this.fetchAggregatedData();
        });
    }

    public componentDidUpdate(_, prevState) {
        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);
        const aggregateSortBy_string = JSON.stringify(this.state.aggregateSortBy);
        const aggregatePrevSortBy_string = JSON.stringify(prevState.aggregateSortBy);

        if (this.state.timeframe !== prevState.timeframe) {
            if (this.state.timeframe >= 0) {
                this.setState(
                    {
                        endDate: new Date(),
                        endDateInMs: new Date().getTime(),
                        startDate: this.getStartDate(new Date()),
                        startDateInMs: this.getStartDate(new Date()).getTime(),
                    },
                    () => {
                        this.transactions_init();
                        if (this.state.selectedTab === 1)
                            this.fetchAggregatedData();
                    })
            }
        }

        if (this.state.timeframe === -1 && (prevState.endDate.valueOf() !== this.state.endDate.valueOf() || prevState.startDate.valueOf() !== this.state.startDate.valueOf())) {
            this.setState(
                {
                    endDateInMs: this.getDateInMs(this.state.endDate),
                    startDateInMs: this.getDateInMs(this.state.startDate)
                },
                () => {
                    this.transactions_init();
                    if (this.state.selectedTab === 1)
                        this.fetchAggregatedData();
                })
        }

        if ((filters_string !== prevFilters_string) || (sortBy_string !== prevSortBy_string)) {
            this.setState(
                () => {
                    this.transactions_init();
                    if (this.state.selectedTab === 1)
                        this.fetchAggregatedData();this.fetchAggregatedData();
                }
            );
        }

        if (aggregateSortBy_string !== aggregatePrevSortBy_string) {
            this.setState(
                {
                    aggregatedTransactions: {
                        data: this.sortAggregatedTransactions(this.state.aggregatedTransactions.data),
                        refreshing: false
                    }
                }
            );
        }
    }

    // Method to fetch aggregated data
    fetchAggregatedData = async () => {
        try {
            const query = {
                gatewayId: this.props.gateways.selected.data.gatewayId,
                startTime: this.state.startDateInMs,
                endTime: this.state.endDateInMs,
                accountId: this.props.gateways.selected.data.accountId,
                environmentId: this.props.gateways.selected.data.environmentId,
                operations: this.state.filters.operations,
                resources: this.state.filters.resources,
            }
            this.setState({status: "recalc"}, () => this.runAnalyticsQuery("gtw-transactions-aggregated", query).then(res => {
                let preparedData = [];
                res.data.aggregations.group_by_fhir_resource.buckets.forEach(bucket => {
                    let record: any = {};
                    record.resourceType = bucket.key.split(":")[0];
                    record.operationType = bucket.key.split(":")[1];
                    record.count = bucket.doc_count;
                    record.avgTime = bucket.avg_time.value;
                    record.medianTime = bucket.median_time.values["50.0"];
                    preparedData.push(record);
                })

                this.setState({
                    aggregatedTransactions: {
                        data: preparedData,
                        refreshing: false
                    },
                    status: "ready"
                });
            }));
        } catch (error) {
            console.error("Error fetching aggregated data", error);
        }
    };

    // Method to handle tab change
    handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({ selectedTab: newValue });
        if (newValue === 1) {
            this.fetchAggregatedData();
        }
    };


    public render() {
        if (this.state.transactions.data === null) {
            return <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
        } else {
            return <div className="gtw-transactions-container" data-qa-gtw-transactions-container>
                <Tabs
                    value={this.state.selectedTab}
                    onChange={this.handleTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    style={{backgroundColor: "white"}}
                >
                    <Tab label="Detailed Transactions" />
                    <Tab label="Aggregated Transactions" />
                </Tabs>
                <Box>
                    {this.state.selectedTab === 0 && <TransactionsTable {...this.props} state={this.state} onSetState={this.onSetState} transactions_init={this.transactions_init.bind(this)} setFilterItem={this.setFilterItem}/>}
                    {this.state.selectedTab === 1 && <AggregatedTransactionsTable {...this.props} state={this.state} onSetState={this.onSetState} transactions_init={this.transactions_init.bind(this)} setFilterItem={this.setFilterItem}/>}
                </Box>
            </div>
        }
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private getStartDate(endDate) {
        let midnight = moment.tz(endDate - this.state.timeframe, this.props.ui.timeZone).startOf("day");
        return new Date(midnight.format());
    }

    private getDateInMs(date) {
        let midnight = moment.tz(moment(date).format("YYYY-MM-DD"), this.props.ui.timezone);
        return new Date(midnight.format()).getTime();
    }

    private runAnalyticsQuery(dataType, query) {
        return axiosWrapper(this.props.config.analyticsService, dataType, "POST", query)
    };

    private processTransactions(transactions) {
        let praparedData = transactions;
        // let preparedData = this.sortTransactions(transactions);

        return praparedData;
    }

    private sortAggregatedTransactions(transactions) {
        const aggregateSortBy = this.state.aggregateSortBy;
        let sorted = $.extend(true, [], transactions);

        let sortString = (a, b, prop) => a[prop].localeCompare(b[prop]);
        let sortInt = (a, b, prop, offset) => {
            if (a[prop] < b[prop]) return 1 * offset;
            else if (a[prop] > b[prop]) return -1 * offset;
            else return 0;
        };

        if (aggregateSortBy.resourceType !== undefined) {
            sorted.sort((a, b) => sortString(a, b, "resourceType"));
            aggregateSortBy.resourceType === false && sorted.reverse();
        }

        if (aggregateSortBy.operationType !== undefined) {
            sorted.sort((a, b) => sortString(a, b, "operationType"));
            aggregateSortBy.operationType === false && sorted.reverse();
        }

        if (aggregateSortBy.avgTime !== undefined) {
            sorted.sort((a, b) => sortInt(a, b, "avgTime", aggregateSortBy.avgTime === true ? 1 : -1));
        }

        if (aggregateSortBy.medianTime !== undefined) {
            sorted.sort((a, b) => sortInt(a, b, "medianTime", aggregateSortBy.medianTime === true ? 1 : -1));
        }

        if (aggregateSortBy.count !== undefined) {
            sorted.sort((a, b) => sortInt(a, b, "count", aggregateSortBy.count === true ? 1 : -1));
        }

        return sorted;
    }

    private transactions_init(page = 1) {
        page--;
        let query = this.transactions_preProcess(page);
        this.setState({status: "recalc"}, () => this.runAnalyticsQuery("gtw-all-transactions", query).then(res => {
            const allTransactions = {
                data: this.transactions_postProcess(res.data),
                refreshing: false
            };
            const fhirResource = {};
            allTransactions.data.map(t => fhirResource[t.fhirResource] = false);

            this.setState({
                transactions: {
                    data: this.processTransactions(allTransactions.data),
                    refreshing: false
                },
                allTransactions,
                transactionsPagination: res.data.page,
                status: "ready"
            });
        }));
    }

    private transactions_preProcess(page) {
        const query = {
            startTime: this.state.startDateInMs,
            endTime: this.state.endDateInMs,
            gatewayId: this.props.gateways.selected.data.gatewayId,
            accountId: this.props.gateways.selected.data.accountId,
            environmentId: this.props.gateways.selected.data.environmentId,
            size: this.state.filters.pageSize,
            page,
            operations: this.state.filters.operations,
            resources: this.state.filters.resources,
            sortBy: this.state.sortBy
        }

        return query;
    }

    private transactions_postProcess(rawData) {
        let preparedData = [];
        rawData.hits.hits.forEach(hit => {
            let record: any = {};
            record.clientName = ""
            this.state.allActivatedApps.forEach((app) => {
                if (app.clientId === hit._source.client_id) {
                    record.clientName = app.name;
                }
            })
            record.clientId = hit._source.client_id || "";
            record.fhirResource = hit._source.fhir_resource || "";
            record.timestamp = hit._source.timestamp;
            record.interactionLevel = hit._source.tx_op_fhir_level || "";
            record.type = hit._source.tx_op || "";
            record.subtype = hit._source.tx_op_fhir || "";
            record.extendedOperationName = hit._source.tx_op_extended_name || "";
            record.userId = hit._source.user_id || "";
            record.sessionId = hit._source.session_id || "";
            record.gatewayRequestType = hit._source.gateway_request_type || "";
            record.accountId = hit._source.account_id || "";
            record.environmentId = hit._source.environment_id || "";
            record.gatewayId = hit._source.gateway_id || "";
            record.clientType = hit._source.client_type || "";
            record.time = hit._source.time || "";
            record.dataAdapterId = hit._source.data_adapter_id || "";
            preparedData.push(record);
        })

        return preparedData;
    }

    private getResourceFilter = () => {
        const query = {
            startTime: this.state.endDateInMs - 31556926000,
            endTime: this.state.endDateInMs,
            gatewayId: this.props.gateways.selected.data.gatewayId,
            accountId: this.props.gateways.selected.data.accountId,
            environmentId: this.props.gateways.selected.data.environmentId,
        }
        const filters = []
        this.runAnalyticsQuery("gtw-resource-filter", query).then(res => {
            res.data.aggregations.group_by_fhir_resource.buckets.forEach((bucket) => {
                filters.push(bucket.key)
            })
            this.setState({resourceFilterValues: filters});
        });
    }

    private setFilterItem = (item, value) => {
        const filters = {...this.state.filters};
        filters[item] = value;
        if (item === "startTime") {
            delete filters.period;
        }
        this.setState({filters})
    }

}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Transactions));
