import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import colors from "../../../../../lib/legacy-color-palette";
import axiosWrapper from "../../../../../lib/axiosWrapper";
import consoleLogger from "../../../../../lib/consoleLogger";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import * as actions from "../../../../../redux/actions/";
import {Divider, Toolbar, MenuItem, Select, Checkbox, Switch, Card, Snackbar, Menu, IconButton, FormGroup, FormHelperText, FormControlLabel, Tooltip, Typography} from "@material-ui/core";
import {Refresh, MoreVert, Unarchive, DateRange, Help} from "@material-ui/icons"
import AppLaunchesOT from "../../../Apps/AppEdit/Usage/UsageGraphs/AppLaunchesOT"
import TopResourcesHorizontal from "../../../Apps/AppEdit/Usage/UsageGraphs/TopResourcesHorizontal";
import ChipDropDown from "../../../../Widgets/ChipDropDown";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import {getDateDisplay, getDateWithZoneDisplay, getFullDateDisplay} from "../../../../../lib/utils";
import getEnvironmentName from "../../../../../lib//getEnvironmentName";
import TotalCount from "../../../../Widgets/Editor/UsageTab/TotalCount";
import LabeledSwitch from "../../../../Widgets/LabeledSwitch";
import "./style.less";
interface TotalItems {
    totalLaunches: any;
    cardReturnedPercentage?: any;
    totalCards?: any
    totalTransactions: any;
    totalInvocations?: any; // Add the missing property
}

let moment = require("moment-timezone");

const colorQueue = [
    colors.deepPurple600,
    colors.teal600,
    colors.amber600,
    colors.blue600,
    colors.brown600,
    colors.deepOrange600,
    colors.green600,
    colors.red600,
    colors.yellow600,
    colors.lime600
];

export class Analytics extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            status: "init",
            assignedColors: {},
            topResourcesByFilter: {
                data: null,
                refreshing: false
            },
            topResourcesTotal: {
                data: null,
                refreshing: false
            },
            topResourcesLatency: {
                data: null,
                refreshing: false
            },
            launchesOverTimeByFilter: {
                data: null,
                refreshing: false
            },
            launchesOverTimeTotal: {
                data: null,
                refreshing: false
            },
            invocationsOverTimeByFilter: {
                data: null,
                refreshing: false
            },
            noCardsInvocationsOverTimeByFilter: {
                data: null,
                refreshing: false
            },
            invocationsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            cardsOverTimeByFilter: {
                data: null,
                refreshing: false
            },
            cardsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeByFilter: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            totalTransactions: {
                data: null,
                refreshing: false
            },
            totalLaunches: {
                data: null,
                refreshing: false
            },
            totalInvocations: {
                data: null,
                refreshing: false
            },
            totalNoCardsInvocations: {
                data: null,
                refreshing: false
            },
            totalCards: {
                data: null,
                refreshing: false
            },
            timeframe: 2678400000,
            timeframeValue: 2678400000,
            startTime: 0,
            endTime: 0,
            timeZone: "US/Mountain",
            startOf: "day",
            interval: "day",
            allActivatedApps: [{name: "interopiO Context Resolver", appClientId: "smart_context_resolver", appId: "interopiO Context Resolver" }, {name: "interopiO Portal", appClientId: "interopio_portal", appId: "interopiO Portal"}, {
                name: "Anonymous",
                appClientId: "anonymous",
                appId: "Anonymous"
            }],
            allActivatedServices: [],
            visibleApps: [],
            visibleServices: [],
            filteredApps: [],
            filteredServices: [],
            allPractices: [],
            visiblePractices: [],
            filteredPractices: [],
            openMenu: false,
            snackbar: {
                open: false,
                message: "Downloading CSV...",
                autoHideDuration: null
            },
            filterType: "clients"
        };

        this.topResources_init = this.topResources_init.bind(this);
        this.topResources_refresh = this.topResources_refresh.bind(this);
        this.launchesOverTime_init = this.launchesOverTime_init.bind(this);
        this.launchesOverTime_refresh = this.launchesOverTime_refresh.bind(this);
        this.handleTimeSpanChange = this.handleTimeSpanChange.bind(this);
        this.refreshAll = this.refreshAll.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.getUpdatedTotalValues = this.getUpdatedTotalValues.bind(this);
        this.handleVisibleItemsChange = this.handleVisibleItemsChange.bind(this)
    }

    public componentDidMount() {
        let allServices = []
        this.props.gateways.selected.data.cdsServiceSetGatewayActivations.forEach((set) => {
            set.cdsServiceGatewayActivations.forEach((service) => {
                service.cdsServiceSetId = set.cdsServiceSetId;
                allServices.push(service);
            })
        })
        this.setState({
            endTime: new Date().getTime(),
            allActivatedApps: this.props.gateways.selected.data.appActivations.filter((value, index, self) => {
                return self.findIndex(v => v.appId === value.appId) === index;
            }).concat(this.state.allActivatedApps),
            allActivatedServices: allServices,
            status: "ready"
        }, () => {
            this.setAllPractices();
            this.setVisibleApps();
            this.assignChartColors();
            this.init();
        });
    }

    private setAllPractices() {
        const {accountId} = getHostnameInfo();
        const query = {
            gatewayId: this.props.gateways.selected.data.gatewayId,
            environmentId: this.props.gateways.selected.data.environmentId,
            accountId
        }

        this.runAnalyticsQuery("all-practices", query).then(res => {
            let practices = res.data.aggregations.unique_practice_ids.buckets.map(bucket => {
                consoleLogger.log(bucket)
                return {
                    id: bucket.key,
                    name: bucket.unique_practice_names.buckets[0]?.key || bucket.key
                };
            });
            let vp = [];
            practices.forEach((practice) => {
                if (practice.name) {
                    vp.push(practice.name);
                } else {
                    vp.push(practice.id);
                }
            });
            this.setState({allPractices: practices, visiblePractices: vp, filteredPractices: vp}, () => this.assignChartColors());
        });

    }

    public render() {
        const styles = {
            cardChart: {
                display: "inline-block",
                margin: "5px"
            },
            cardChartText: {padding: 0},
            chartMargins: {top: 15, right: 30, bottom: 5, left: 0},
            subTitle: {margin: 0},
            title: {marginBottom: 0}
        };

        if (this.state.status === "init") {
            return <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
        }

        let cardReturnedPercentage = {...this.state.totalNoCardsInvocations, data: null};
        if (this.state.totalInvocations.data === 0 && this.state.totalNoCardsInvocations.data !== null) {
            cardReturnedPercentage = {...this.state.totalNoCardsInvocations, data: 0};
        } else if (this.state.totalInvocations.data !== null && this.state.totalNoCardsInvocations.data !== null) {
            const percent = Math.round(((this.state.totalInvocations.data - this.state.totalNoCardsInvocations.data) * 100) / this.state.totalInvocations.data);
            cardReturnedPercentage = {...this.state.totalNoCardsInvocations, data: percent};
        }

        let totalItems: TotalItems = {
            totalLaunches: {...this.state.totalLaunches, displayName: "App Launches"},
            totalTransactions: {...this.state.totalTransactions, displayName: "FHIR Transactions"}
        }
        if (this.state.filterType === "clients" && this.state.allActivatedServices.length > 0) {
            totalItems.totalInvocations = {...this.state.totalInvocations, displayName: "CDS Invocations"}
            totalItems.totalCards = {...this.state.totalCards, displayName: "CDS Cards Returned"}
            totalItems.cardReturnedPercentage = {...cardReturnedPercentage, displayName: "Card Returning Invocations", suffix: "%", zeroValue: "--"}
        }

        return <div style={{width: "100%"}}>
            <div className="app-analytics" data-qa-gtw-usage-container>
                {this.renderToolbar()}
                <TotalCount items={totalItems}/>
                <div style={{margin: "30px 10px 10px"}}>Comparing by {this.state.filterType === "practices" ? "Practice" : "Client"}</div>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.launchesOverTimeByFilter_render(styles)}
                </Card>
                {this.state.filterType === "clients" && this.state.allActivatedServices.length > 0 &&
                    <Card id="chart-top-resources" className="app-chart-card">
                        {this.invocationsOverTimeByFilter_render(styles)}
                    </Card>
                }
                {this.state.filterType === "clients" && this.state.allActivatedServices.length > 0 &&
                    <Card id="chart-top-resources" className="app-chart-card">
                        {this.cardsOverTimeByFilter_render(styles)}
                    </Card>
                }
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.topResourcesByFilter_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.transactionsOverTimeByFilter_render(styles)}
                </Card>
                <div style={{margin: "30px 10px 10px"}}>Total Count</div>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.launchesOverTimeTotal_render(styles)}
                </Card>
                {this.state.filterType === "clients" && this.state.allActivatedServices.length > 0 &&
                    <Card id="chart-top-resources" className="app-chart-card">
                        {this.invocationsOverTimeTotal_render(styles)}
                    </Card>
                }
                {this.state.filterType === "clients" && this.state.allActivatedServices.length > 0 &&
                    <Card id="chart-top-resources" className="app-chart-card">
                        {this.cardsOverTimeTotal_render(styles)}
                    </Card>
                }
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.topResourcesTotal_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.transactionsOverTimeTotal_render(styles)}
                </Card>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.topResourcesLatency_render(styles)}
                </Card>
                <br/>
                <br/>
                <Divider/>
                <Snackbar open={this.state.snackbar.open} message={this.state.snackbar.message} autoHideDuration={this.state.snackbar.autoHideDuration}
                    style={{backgroundColor: this.props.ui.xtheme.palette.colorBlueDark, textAlign: "center"}}/>
            </div>
        </div>;
    }

    private init() {
        Promise.all([this.topResources_init(), this.launchesOverTime_init(), this.invocationsOverTime_init(), this.transactionsOverTime_init()])
            .then(([topData, lotData, iotData, trotData]) => {
                const topResourcesTotalPostProcessData = this.topResourcesTotal_postProcess(topData);
                this.setState({
                    topResourcesByFilter: {
                        data: this.topResourcesByFilter_postProcess(topData),
                        refreshing: false
                    },
                    topResourcesTotal: {
                        data: topResourcesTotalPostProcessData.preparedData,
                        refreshing: false
                    },
                    topResourcesLatency: {
                        data: topResourcesTotalPostProcessData.preparedLatencyData,
                        refreshing: false
                    },
                    launchesOverTimeByFilter: {
                        data: this.launchesOverTimeByFilter_postProcess(lotData),
                        refreshing: false
                    },
                    launchesOverTimeTotal: {
                        data: this.launchesOverTimeTotal_postProcess(lotData),
                        refreshing: false
                    },
                    totalLaunches: {
                        data: this.calculateTotalLaunches(lotData),
                        refreshing: false
                    },
                    invocationsOverTimeByFilter: {
                        data: this.invocationsOverTimeByFilter_postProcess(iotData),
                        refreshing: false
                    },
                    noCardsInvocationsOverTimeByFilter: {
                        data: this.noCardsInvocationsOverTimeByFilter_postProcess(iotData),
                        refreshing: false
                    },
                    invocationsOverTimeTotal: {
                        data: this.invocationsOverTimeTotal_postProcess(iotData),
                        refreshing: false
                    },
                    totalInvocations: {
                        data: this.calculateTotalInvocations(iotData),
                        refreshing: false
                    },
                    totalNoCardsInvocations: {
                        data: this.calculateTotalNoCardsInvocatiuons(iotData),
                        refreshing: false
                    },
                    cardsOverTimeByFilter: {
                        data: this.cardsOverTimeByFilter_postProcess(iotData),
                        refreshing: false
                    },
                    cardsOverTimeTotal: {
                        data: this.cardsOverTimeTotal_postProcess(iotData),
                        refreshing: false
                    },
                    totalCards: {
                        data: this.calculateTotalCards(iotData),
                        refreshing: false
                    },
                    transactionsOverTimeByFilter: {
                        data: this.transactionsOverTimeByFilter_postProcess(trotData),
                        refreshing: false
                    },
                    transactionsOverTimeTotal: {
                        data: this.transactionsOverTimeTotal_postProcess(trotData),
                        refreshing: false
                    },
                    totalTransactions: {
                        data: this.calculateTotalTransactions(trotData),
                        refreshing: false
                    }
                });
            });
    }

    private refreshAll() {
        this.topResources_refresh();
        this.launchesOverTime_refresh();
        this.invocationsOverTime_refresh();
        this.transactionsOverTime_refresh();
        this.init();
    }

    private handleFilterTypeChange = (event) => {
        let va = [];
        let vs = [];
        let vp = [];
        this.state.allActivatedApps.forEach((app) => {
            va.push(app.appId);
        });
        this.state.allActivatedServices.forEach((service) => {
            vs.push(service.id);
        });
        this.state.allPractices.forEach((practice) => {
            vp.push(practice.name);
        });
        this.setState({
            filterType: event.target.checked ? 'practices' : 'clients',
            visibleActivation: va,
            filteredActivations: va,
            visibleServices: vs,
            filteredServices: vs,
            visiblePractices: vp,
            filteredPractices: vp
        }, () => {
            this.refreshAll()
        });
    }

    private setVisibleApps() {
        let va = [];
        let vs = [];
        this.state.allActivatedApps.forEach((app) => {
            va.push(app.appId);
        });
        this.state.allActivatedServices.forEach((service) => {
            vs.push(service.id);
        });
        this.setState({visibleApps: va, visibleServices: vs, filteredApps: va, filteredServices: vs});
    }

    private assignChartColors() {
        let assignedColors = {
            "total": colors.indigo600,
            latency: "#43A047",
        };
        for (let x = 0; x < this.state.allActivatedApps.length; x++) {
            let colorIndex = x;
            if (colorIndex > colorQueue.length) {
                colorIndex = colorIndex % colorQueue.length;
            }

            assignedColors[this.state.allActivatedApps[x].appId] = colorQueue[colorIndex];
        }
        for (let y = this.state.allActivatedApps.length; y < this.state.allActivatedServices.length + this.state.allActivatedApps.length; y++) {
            let colorIndex = y;
            if (colorIndex > colorQueue.length) {
                colorIndex = colorIndex % colorQueue.length;
            }

            assignedColors[this.state.allActivatedServices[y - this.state.allActivatedApps.length].id] = colorQueue[colorIndex];
        }
        for (let x = 0; x < this.state.allPractices.length; x++) {
            let colorIndex = x;
            if (colorIndex > colorQueue.length) {
                colorIndex = colorIndex % colorQueue.length;
            }

            assignedColors[this.state.allPractices[x].name] = colorQueue[colorIndex];
        }
        this.setState({assignedColors});
    }

    private getChartStartTimeInMillis() {
        let midnight = moment.tz(this.state.endTime - this.state.timeframe, this.props.ui.timeZone).startOf(this.state.startOf)
        this.setState({startTime: new Date(midnight.format()).getTime()});
        return new Date(midnight.format()).getTime();
    }

    private runAnalyticsQuery(dataType, query) {
        return axiosWrapper(this.props.config.analyticsService, dataType, "POST", query)
    }

    private getTimeZone() {
        return this.props.ui.timeZone
    }

    private launchesOverTime_init() {
        let query = this.launchesOverTime_preProcess();
        return this.runAnalyticsQuery("gtw-lot-data", query).then(res => res.data);
    }

    private launchesOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }

        let practices = [];
        this.state.allPractices.forEach((practice) => {
            practices.push(practice.id);
        })

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            gatewayId: this.props.gateways.selected.data.gatewayId,
            apps: this.state.allActivatedApps,
            accountId: this.props.gateways.selected.data.accountId,
            environmentId: this.props.gateways.selected.data.environmentId,
            filterType: this.state.filterType,
            practices
        }

        return query;
    }

    private launchesOverTimeByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        if (this.state.filterType === "practices") {
            for (let i = 0; i < this.state.allPractices.length; i++) {
                let curPractice = this.state.allPractices[i];
                preparedData.series.push(curPractice.name);
            }
        } else {
            for (let i = 0; i < this.state.allActivatedApps.length; i++) {
                let curApp = this.state.allActivatedApps[i];
                preparedData.series.push(curApp.appId);
            }
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            if (this.state.filterType === "practices") {
                for (let key in curBucket["3"]["buckets"]) {
                    let appKey = key.split(":")[1];
                    let activationKey;
                    for (let practice of this.state.allPractices) {
                        if (appKey === practice.id) {
                            activationKey = practice.name
                            curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
                        }
                    }
                }
            } else {
                for (let key in curBucket["3"]["buckets"]) {
                    let appKey = key.split(":")[1];
                    let activationKey;
                    for (let app of this.state.allActivatedApps) {
                        if (appKey === app.appClientId) {
                            activationKey = app.appId
                        }
                    }
                    curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
                }
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private launchesOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            let intervals;
            let add;
            let datapointName = this.getChartStartTimeInMillis()
            switch (this.state.timeframe) {
                case 0:
                    intervals = Math.floor((new Date().getTime() - datapointName) / 3600000)
                    add = "hour"
                    break;
                case 86400000:
                    intervals = 24
                    add = "hour"
                    break;
                case 604800000:
                    intervals = 7
                    add = "day"
                    break;
                case 2678400000:
                    intervals = 30
                    add = "day"
                    break;
                case 8035200000:
                    intervals = 14
                    add = "week"
                    break;
                case 31536000000:
                    intervals = 12
                    add = "month"
                    break;
            }
            for (let i = 0; i <= intervals; i++) {
                let datapoint = {}
                datapoint["name"] = datapointName;
                datapoint["total"] = 0;
                placeholeder.push(datapoint)
                datapointName = new Date(moment.tz(datapointName, this.props.ui.timeZone).add(1, add).format()).getTime();
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    }

    private calculateTotalLaunches(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private launchesOverTime_refresh() {
        this.setState({
            launchesOverTimeByFilter: {
                data: this.state.launchesOverTimeByFilter.data,
                refreshing: true
            },
            launchesOverTimeTotal: {
                data: this.state.launchesOverTimeTotal.data,
                refreshing: true
            },
            totalLaunches: {
                data: this.state.totalLaunches.data,
                refreshing: true
            }
        });
    }

    private launchesOverTimeByFilter_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.launchesOverTimeByFilter.data} styles={styles} launchesOverTime_refresh={this.launchesOverTime_refresh}
            title={`App Sessions on Gateway By ${this.state.filterType === "practices" ? "Practice" : "Client"}`}/>
    }

    private launchesOverTimeTotal_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.launchesOverTimeTotal.data} styles={styles} launchesOverTime_refresh={this.launchesOverTime_refresh}
            legend={false} title="Total App Sessions on Gateway"/>
    }

    private invocationsOverTime_init() {
        let query = this.invocationsOverTime_preProcess();
        return this.runAnalyticsQuery("gtw-iot-data", query).then(res => res.data);
    }

    private invocationsOverTime_preProcess() {

        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            cdsServices: this.state.allActivatedServices,
            gatewayId: this.props.gateways.selected.data.gatewayId,
            accountId: this.props.gateways.selected.data.accountId,
            environmentId: this.props.gateways.selected.data.environmentId
        }


        return query;
    }

    private invocationsOverTimeByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allActivatedServices.length; i++) {
            let curService = this.state.allActivatedServices[i];
            preparedData.series.push(curService.id);
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let serviceKey = key.split(":")[1];
                let activationKey;
                for (let service of this.state.allActivatedServices) {
                    let parsedServiceKey = serviceKey.toString();
                    let index = parsedServiceKey.lastIndexOf("/");
                    parsedServiceKey = parsedServiceKey.slice(index + 1);
                    if (parsedServiceKey === service.id) {
                        activationKey = service.id
                    }
                }
                curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private invocationsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private calculateTotalInvocations(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private calculateTotalNoCardsInvocatiuons(rawData) {
        let totalNoCardsInvocations = 0;
        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];

            for (let key in curBucket["3"]["buckets"]) {
                totalNoCardsInvocations += curBucket["3"]["buckets"][key]["no_cards_invocations"].doc_count;
            }
        }

        return totalNoCardsInvocations;
    }


    private noCardsInvocationsOverTimeByFilter_postProcess(rawData) {
        let preparedData = {
            entries: []
        };

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let serviceKey = key.split(":")[1];
                let activationKey;
                for (let service of this.state.allActivatedServices) {
                    let parsedServiceKey = serviceKey.toString();
                    let index = parsedServiceKey.lastIndexOf("/");
                    parsedServiceKey = parsedServiceKey.slice(index + 1);
                    if (parsedServiceKey === service.id) {
                        activationKey = service.id
                    }
                }
                curData[activationKey] = curBucket["3"]["buckets"][key]["no_cards_invocations"].doc_count;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private invocationsOverTime_refresh() {
        this.setState({
            invocationsOverTimeByFilter: {
                data: this.state.invocationsOverTimeByFilter.data,
                refreshing: true
            },
            invocationsOverTimeTotal: {
                data: this.state.invocationsOverTimeTotal.data,
                refreshing: true
            },
            totalInvocations: {
                data: this.state.totalInvocations.data,
                refreshing: true
            },
            totalNoCardsInvocations: {
                data: this.state.totalNoCardsInvocations.data,
                refreshing: true
            },
            cardsOverTimeByFilter: {
                data: this.state.cardsOverTimeByFilter.data,
                refreshing: true
            },
            cardsOverTimeTotal: {
                data: this.state.cardsOverTimeTotal.data,
                refreshing: true
            },
            totalCards: {
                data: this.state.totalCards.data,
                refreshing: true
            }
        });
    }

    private invocationsOverTimeByFilter_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.invocationsOverTimeByFilter.data} styles={styles}
            launchesOverTime_refresh={this.invocationsOverTime_refresh} title="CDS Service Invocations By Client"/>
    }

    private invocationsOverTimeTotal_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.invocationsOverTimeTotal.data} styles={styles}
            launchesOverTime_refresh={this.invocationsOverTime_refresh} legend={false} title="Total CDS Service Invocations"/>
    }

    private cardsOverTimeByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allActivatedServices.length; i++) {
            let curService = this.state.allActivatedServices[i];
            preparedData.series.push(curService.id);
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let serviceKey = key.split(":")[1];
                let activationKey;
                for (let service of this.state.allActivatedServices) {
                    let parsedServiceKey = serviceKey.toString();
                    let index = parsedServiceKey.lastIndexOf("/");
                    parsedServiceKey = parsedServiceKey.slice(index + 1);
                    if (parsedServiceKey === service.id) {
                        activationKey = service.id
                    }
                }
                curData[activationKey] = curBucket["3"]["buckets"][key]["4"].value;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private cardsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            let total = 0
            for (let key in curBucket["3"]["buckets"]) {
                total += curBucket["3"]["buckets"][key]["4"].value;
            }
            curData["name"] = curBucket.key;
            curData["total"] = total;

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private calculateTotalCards(rawData) {
        let totalCards = 0;
        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];

            for (let key in curBucket["3"]["buckets"]) {
                totalCards += curBucket["3"]["buckets"][key]["4"].value;
            }
        }

        return totalCards;
    }

    private cardsOverTimeByFilter_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.cardsOverTimeByFilter.data} styles={styles} launchesOverTime_refresh={this.invocationsOverTime_refresh}
            title="CDS Cards Returned By Client"/>
    }

    private cardsOverTimeTotal_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.cardsOverTimeTotal.data} styles={styles} launchesOverTime_refresh={this.invocationsOverTime_refresh}
            legend={false} title="Total CDS Cards Returned"/>
    }

    private topResources_init() {
        let query = this.topResources_preProcess();
        return this.runAnalyticsQuery("gtw-top-data", query).then(res => res.data);
    }

    private topResources_preProcess() {
        const {accountId} = getHostnameInfo();

        let query = {
            gatewayId: this.props.gateways.selected.data.gatewayId,
            endTime: new Date().getTime(),
            startTime: this.getChartStartTimeInMillis(),
            environmentId: this.props.gateways.selected.data.environmentId,
            accountId,
            filterType: this.state.filterType
        }

        return query;
    }

    private topResourcesByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        if (this.state.filterType === "practices") {
            for (let i = 0; i < this.state.allPractices.length; i++) {
                let curPractice = this.state.allPractices[i];
                preparedData.series.push(curPractice.name);
            }
        } else {
            for (let i = 0; i < this.state.allActivatedApps.length; i++) {
                let curApp = this.state.allActivatedApps[i];
                preparedData.series.push(curApp.appId);
            }

            for (let i = 0; i < this.state.allActivatedServices.length; i++) {
                let curService = this.state.allActivatedServices[i];
                preparedData.series.push(curService.id);
            }
        }

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;

        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["Anonymous"] = curBucket?.missing_client_id?.doc_count;
            const envBuckets = curBucket.group_by_client_id.buckets;

            for (let j = 0; j < envBuckets.length; j++) {
                let curEnvBucket = envBuckets[j];
                let appKey = curEnvBucket.key
                let activationKey;
                consoleLogger.log(curEnvBucket)
                if (this.state.filterType === "practices") {
                    for (let practice of this.state.allPractices){
                        if (appKey === practice.id) {
                            consoleLogger.log("here")
                            activationKey = practice.name
                            curData[activationKey] = curEnvBucket.doc_count;
                        }
                    }
                } else {
                    for (let app of this.state.allActivatedApps) {
                        if (appKey === app.appClientId) {
                            activationKey = app.appId
                        }
                    }
                    for (let service of this.state.allActivatedServices) {
                        let serviceKey = appKey.toString();
                        let index = serviceKey.lastIndexOf("/");
                        serviceKey = serviceKey.slice(index + 1);
                        if (serviceKey === service.id) {
                            activationKey = service.id
                        }
                    }
                    curData[activationKey] = curEnvBucket.doc_count;
                }
            }
            consoleLogger.log(curData)

            preparedData.entries = preparedData.entries.concat(curData);
        }

        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                datapoint["name"] = resources[i];
                datapoint[this.props.apps.selected.data.name] = 0;
                placeholeder.push(datapoint)
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    }

    private topResourcesTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        let preparedLatencyData = {
            entries: [],
            series: ["latency"]
        };

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;
        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curData = {};
            let curLatencyData = {};
            curData["name"] = curBucket.key;
            curLatencyData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;
            curLatencyData["latency"] = curBucket.avg_time ? curBucket.avg_time.value.toFixed(2) : null;

            preparedData.entries = preparedData.entries.concat(curData);
            preparedLatencyData.entries = preparedLatencyData.entries.concat(curLatencyData);
        }
        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedData.entries.length === 0) {
            let placeholeder = []
            let placeholederLatency = []
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                let datapointLatency = {}
                datapoint["name"] = resources[i]
                datapointLatency["name"] = resources[i]
                datapoint["total"] = 0;
                datapointLatency["latency"] = 0;
                placeholeder.push(datapoint)
                placeholederLatency.push(datapointLatency)
            }
            preparedData.entries = placeholeder
            preparedLatencyData.entries = placeholederLatency
        }

        return {preparedData, preparedLatencyData};
    }

    private topResources_refresh() {
        this.setState({
            topResourcesByFilter: {
                data: this.state.topResourcesByFilter.data,
                refreshing: true
            },
            topResourcesTotal: {
                data: this.state.topResourcesTotal.data,
                refreshing: true
            }
        });
    }

    private topResourcesByFilter_render(styles) {
        return <TopResourcesHorizontal state={this.state} data={this.state.topResourcesByFilter.data} sortBy="name" styles={styles} topResources_refresh={this.topResources_refresh}
            legend={true} title={`Resource Transactions By ${this.state.filterType === "practices" ? "Practice" : "Client"}`}/>
    }

    private topResourcesTotal_render(styles) {
        return <TopResourcesHorizontal state={this.state} data={this.state.topResourcesTotal.data} sortBy="name" styles={styles} topResources_refresh={this.topResources_refresh}
            legend={false} title="Resource Transactions - Total"/>
    }

    private topResourcesLatency_render(styles) {
        return <TopResourcesHorizontal type="latency" state={this.state} data={this.state.topResourcesLatency.data} sortBy="name" styles={styles}
            topResources_refresh={this.topResources_refresh} legend={false} title="Resource Transactions - Average Latency"/>
    };

    private transactionsOverTime_init() {
        let query = this.transactionsOverTime_preProcess();
        return this.runAnalyticsQuery("gtw-trot-data", query).then(res => res.data);
    }

    private transactionsOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }
        let clientIds = [];
        this.state.allActivatedApps.forEach((app) => {
            clientIds.push(app.appClientId);
        })
        this.state.allActivatedServices.forEach((service) => {
            clientIds.push(`${this.props.gateways.selected.data.accountId}/${this.props.gateways.selected.data.environmentId}/${service.cdsServiceSetId}/${service.id}`)
        })
        let practices = [];
        this.state.allPractices.forEach((practice) => {
            practices.push(practice.id);
        })

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            gatewayId: this.props.gateways.selected.data.gatewayId,
            clientIds,
            practices,
            filterType: this.state.filterType,
            accountId: this.props.gateways.selected.data.accountId,
            environmentId: this.props.gateways.selected.data.environmentId
        }

        return query;
    }

    private transactionsOverTimeByFilter_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        if (this.state.filterType === "practices") {
            for (let i = 0; i < this.state.allPractices.length; i++) {
                let curPractice = this.state.allPractices[i];
                preparedData.series.push(curPractice.name);
            }
        } else {
            for (let i = 0; i < this.state.allActivatedApps.length; i++) {
                let curApp = this.state.allActivatedApps[i];
                preparedData.series.push(curApp.appId);
            }

            for (let i = 0; i < this.state.allActivatedServices.length; i++) {
                let curService = this.state.allActivatedServices[i];
                preparedData.series.push(curService.id);
            }
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["Anonymous"] = curBucket["missing_client_id"]?.doc_count;
            if (this.state.filterType === "practices") {
                for (let key in curBucket["3"]["buckets"]) {
                    let practiceKey = key.split(":")[1];
                    let activationKey;
                    for (let practice of this.state.allPractices) {
                        if (practiceKey === practice.id) {
                            activationKey = practice.name
                            curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
                        }
                    }
                }
            } else {
                for (let key in curBucket["3"]["buckets"]) {
                    let appKey = key.split(":")[1];
                    let activationKey;
                    for (let app of this.state.allActivatedApps) {
                        if (appKey === app.appClientId && app.appClientId !== "anonymous") {
                            activationKey = app.appId
                        }
                    }
                    for (let service of this.state.allActivatedServices) {
                        let serviceKey = appKey.toString();
                        let index = serviceKey.lastIndexOf("/");
                        serviceKey = serviceKey.slice(index + 1);
                        if (serviceKey === service.id) {
                            activationKey = service.id
                        }
                    }
                    curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
                }
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    }

    private transactionsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            let intervals;
            let add;
            let datapointName = this.getChartStartTimeInMillis()
            switch (this.state.timeframe) {
                case 0:
                    intervals = Math.floor((new Date().getTime() - datapointName) / 3600000)
                    add = "hour"
                    break;
                case 86400000:
                    intervals = 24
                    add = "hour"
                    break;
                case 604800000:
                    intervals = 7
                    add = "day"
                    break;
                case 2678400000:
                    intervals = 30
                    add = "day"
                    break;
                case 8035200000:
                    intervals = 14
                    add = "week"
                    break;
                case 31536000000:
                    intervals = 12
                    add = "month"
                    break;
            }
            for (let i = 0; i <= intervals; i++) {
                let datapoint = {}
                datapoint["name"] = datapointName;
                datapoint[this.props.apps.selected.data.name] = 0;
                placeholeder.push(datapoint)
                datapointName = new Date(moment.tz(datapointName, this.props.ui.timeZone).add(1, add).format()).getTime();
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    }

    private calculateTotalTransactions(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private transactionsOverTime_refresh() {
        this.setState({
            transactionsOverTimeByFilter: {
                data: this.state.transactionsOverTimeByFilter.data,
                refreshing: true
            },
            transactionsOverTimeTotal: {
                data: this.state.transactionsOverTimeTotal.data,
                refreshing: true
            },
            totalTransactions: {
                data: this.state.totalTransactions.data,
                refreshing: true
            }
        });
    }

    private transactionsOverTimeByFilter_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.transactionsOverTimeByFilter.data} styles={styles}
            launchesOverTime_refresh={this.transactionsOverTime_refresh} title={`Transactions on Gateway By ${this.state.filterType === "practices" ? "Practice" : "Client"}`}/>
    }

    private transactionsOverTimeTotal_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.transactionsOverTimeTotal.data} styles={styles}
            launchesOverTime_refresh={this.transactionsOverTime_refresh} legend={false} title="Total Transactions on Gateway"/>
    }

    private renderToolbar = () => {
        let timeframe = getDateDisplay(this.state.startTime, this.props.ui.timeZone) + " - " + getDateWithZoneDisplay(this.state.endTime, this.props.ui.timeZone);
        let labelClients = "Clients";
        let fa = this.state.filteredApps;
        let fs = this.state.filteredServices;
        let filteredClients = [...fa, ...fs];
        if (filteredClients.length && filteredClients.length !== this.state.allActivatedApps.length + this.state.allActivatedServices.length) {
            labelClients = filteredClients[0];
            if (filteredClients.length > 1) {
                labelClients += ` + ${filteredClients.length - 1}`;
            }
        } else if (filteredClients.length === 0) {
            labelClients = "Clients";
        }

        let labelPractices = "Practices";
        if (this.state.filteredPractices.length && this.state.filteredPractices.length !== this.state.allPractices.length) {
            labelPractices = this.state.filteredPractices[0];
            if (this.state.filteredPractices.length > 1) {
                labelPractices += ` + ${this.state.filteredPractices.length - 1}`;
            }
        } else if (this.state.filteredPractices.length === 0) {
            labelPractices = "Practices";
        }

        return <Toolbar className={"analytics-toolbar"} style={{height: "76px", display: "flex", alignItems: "space-between"}}>
            <div style={{flex: 1, display: "flex"}}>
                <DateRange style={{fontSize: "24px", paddingRight: "10px", alignSelf: "center"}}/>
                <div>
                    <Select value={this.state.timeframeValue} onChange={this.handleTimeSpanChange} style={{width: "256px"}}>
                        <MenuItem value={0}>
                            Today
                        </MenuItem>
                        <MenuItem value={86400000}>
                            Yesterday
                        </MenuItem>
                        <MenuItem value={604800000}>
                            Last 7 days
                        </MenuItem>
                        <MenuItem value={2678400000}>
                            Last 30 days
                        </MenuItem>
                        <MenuItem value={8035200000}>
                            Last 90 days
                        </MenuItem>
                        <MenuItem value={31536000000}>
                            Last 365 days
                        </MenuItem>
                        <MenuItem value={-12}>
                            Year-to-date
                        </MenuItem>
                    </Select>
                    <FormHelperText style={{display: "block"}}>
                        {timeframe}
                    </FormHelperText>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <FormGroup style={{alignSelf: "center", marginRight: 20}}>
                    <Typography variant="body2" align="center" color="textSecondary">Filter By:</Typography>
                    <LabeledSwitch disabled={!this.state.allPractices.length} checked={this.state.filterType === 'practices'} onChange={this.handleFilterTypeChange} labelOff='Activations' labelOn="Practices"/>
                </FormGroup>
                <div style={{alignSelf: "center", display: "inline-block"}}>
                    <ChipDropDown label={this.state.filterType === 'practices' ? labelPractices : labelClients} inactiveBackground="#fff" inactiveColor={this.props.muiTheme.palette.textColor}
                        activeBackground={this.props.muiTheme.palette.primary1Color} activeColor="#fff"
                        popover={{
                            title: this.state.filterType === 'practices' ? "Practices" : "Clients",
                            content: <div style={{margin: "8px 16px"}}>
                                <FormControlLabel label={this.state.filterType === 'practices' ? "Select All Practices" : "Select All Clients"} style={{marginBottom: "8px"}}
                                    control={<Switch color="primary"
                                        onChange={this.handleToggle}/>}
                                    checked={this.state.filterType === 'practices' ? this.state.filteredPractices.length === this.state.allPractices.length : filteredClients.length === this.state.allActivatedApps.length + this.state.allActivatedServices.length}
                                />
                                <Divider style={{margin: "8px 0px"}}/>
                                {this.state.filterType !== 'practices' ?
                                    (<div>
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            {this.state.allActivatedApps.sort((a, b) => a.appId.localeCompare(b.appId)).map(item => {
                                                const label = this.state.filterType === 'practices' ? item.name : item.appId;
                                                let index = (this.state.filterType === 'practices' ? this.state.filteredPractices : this.state.filteredApps).indexOf(label);
                                                return <FormControlLabel label={label} key={`client-${label}`}
                                                    control={<Checkbox name={`client-${label}`} checked={index >= 0} color="primary" style={{padding: "2px"}}
                                                        onChange={e => {
                                                            let va = $.extend(true, [], this.state.filteredApps);
                                                            let vs = $.extend(true, [], this.state.filteredServices);
                                                            let vp = $.extend(true, [], this.state.filteredPractices);
                                                            let isChecked = e.target.checked;
                                                            if (isChecked && index < 0) {
                                                                va.push(label)
                                                            } else if (!isChecked && index >= 0) {
                                                                va.splice(index, 1);
                                                            }
                                                            this.handleVisibleItemsChange(va, vs, vp, this.state.filterType);
                                                        }}
                                                    />}/>
                                            })}
                                        </div>
                                        <Divider style={{marginTop: "8px", marginBottom: "8px"}}/>
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            {this.state.allActivatedServices.sort((a, b) => a.id.localeCompare(b.id)).map(service => {
                                                let index = this.state.filteredServices.indexOf(service.id);
                                                return <FormControlLabel label={service.id} key={`client-${service.id}`}
                                                    control={<Checkbox name={`client-${service.id}`} checked={index >= 0} color="primary" style={{padding: "2px"}}
                                                        onChange={e => {
                                                            let va = $.extend(true, [], this.state.filteredApps);
                                                            let vs = $.extend(true, [], this.state.filteredServices);
                                                            let vp = $.extend(true, [], this.state.filteredPractices);
                                                            let isChecked = e.target.checked;
                                                            if (isChecked && index < 0) {
                                                                vs.push(service.id)
                                                            } else if (!isChecked && index >= 0) {
                                                                vs.splice(index, 1);
                                                            }
                                                            this.handleVisibleItemsChange(va, vs, vp, this.state.filterType);
                                                        }}
                                                    />}/>
                                            })}
                                        </div>
                                    </div>)
                                    : (<div style={{display: "flex", flexDirection: "column"}}>
                                        {this.state.allPractices.sort((a, b) => a.name.localeCompare(b.name)).map(item => {
                                            const label = item.name || item.id;
                                            let index = this.state.filteredPractices.indexOf(label);
                                            return <FormControlLabel label={label} key={`client-${label}`}
                                                control={<Checkbox name={`client-${label}`} checked={index >= 0} color="primary" style={{padding: "2px"}}
                                                    onChange={e => {
                                                        let isChecked = e.target.checked;
                                                        let va = $.extend(true, [], this.state.filteredApps);
                                                        let vs = $.extend(true, [], this.state.filteredServices);
                                                        let vp = $.extend(true, [], this.state.filteredPractices);
                                                        if (isChecked && index < 0) {
                                                            vp.push(item.name)
                                                        } else if (!isChecked && index >= 0) {
                                                            vp.splice(index, 1);
                                                        }
                                                        this.handleVisibleItemsChange(va, vs, vp, this.state.filterType);
                                                    }}
                                                />}/>
                                        })}
                                    </div>)
                                }
                            </div>
                        }}
                        isActive={this.isFilterResourcesActive()}
                        onRequestDelete={() => {
                            let va = [];
                            let vs = [];
                            let vp = [];
                            this.state.allActivatedApps.forEach((app) => {
                                va.push(app.appId);
                            });
                            this.state.allActivatedServices.forEach((service) => {
                                vs.push(service.id);
                            });
                            this.state.allPractices.forEach((practice) => {
                                vp.push(practice.name);
                            });
                            this.handleVisibleItemsChange(va, vs, vp, this.state.filterType);
                        }}/>
                </div>
                <Tooltip title="Refresh" placement="bottom">
                    <IconButton onClick={this.refreshAll} disabled={this.state.topResourcesByFilter.refreshing} disableTouchRipple={true}
                        style={{alignSelf: "center", margin: "10px 0 10px 10px"}}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
                <IconButton
                    onClick={() => {
                        const url = "https://support.interopio.com/hc/en-us/articles/360030939012";
                        window.open(url, "_blank");
                    }}
                >
                    <Help/>
                </IconButton>
                <IconButton onClick={e => this.setState({openMenu: true, menuTarget: e.currentTarget})}>
                    <MoreVert/>
                </IconButton>
                <Menu open={this.state.openMenu} anchorEl={this.state.menuTarget}
                    onClose={() => this.setState({openMenu: false, menuTarget: undefined})}>
                    <MenuItem key="right-menu-export-transactions" onClick={() => this.exportTransactionsCsv()}>
                        <Unarchive style={{paddingRight: "24px"}}/> Export Transactions as CSV
                    </MenuItem>
                    <MenuItem key="right-menu-export-launches" onClick={() => this.exportLaunchesCsv()}>
                        <Unarchive style={{paddingRight: "24px"}}/> Export App Launches as CSV
                    </MenuItem>
                    <MenuItem key="right-menu-export-invocations" onClick={() => this.exportInvocationsCsv()}>
                        <Unarchive style={{paddingRight: "24px"}}/> Export CDS Service Invocations as CSV
                    </MenuItem>
                </Menu>
            </div>
        </Toolbar>;
    };

    private handleVisibleItemsChange(visibleApps, visibleServices, visiblePractices, filterType) {
        let va = $.extend(true, [], visibleApps);
        let vs = $.extend(true, [], visibleServices);
        let vp = $.extend(true, [], visiblePractices);
        if (va.length === 0 && vs.length === 0) {
            this.state.allActivatedApps.forEach((app) => {
                va.push(app.appId);
            });
            this.state.allActivatedServices.forEach((service) => {
                vs.push(service.id);
            });
        }
        let lot = $.extend(true, {}, this.state.launchesOverTimeByFilter);
        let iot = $.extend(true, {}, this.state.invocationsOverTimeByFilter);
        let cot = $.extend(true, {}, this.state.cardsOverTimeByFilter);
        let top = $.extend(true, {}, this.state.topResourcesByFilter);
        let trot = $.extend(true, {}, this.state.transactionsOverTimeByFilter);
        lot.data.series = va;
        iot.data.series = vs;
        cot.data.series = vs;
        top.data.series = [...va, ...vs];
        trot.data.series = [...va, ...vs];
        let totalValues = this.getUpdatedTotalValues(va, vs, vp, filterType);

        this.setState({
            filteredApps: visibleApps,
            filteredServices: visibleServices,
            filteredPractices: visiblePractices,
            visibleApps: va,
            visibleServices: vs,
            visiblePractices: vp,
            launchesOverTimeByFilter: lot,
            invocationsOverTimeByFilter: iot,
            cardsOverTimeByFilter: cot,
            topResourcesByFilter: top,
            transactionsOverTimeByFilter: trot,
            launchesOverTimeTotal: totalValues.launchesOverTimeTotal,
            invocationsOverTimeTotal: totalValues.invocationsOverTimeTotal,
            cardsOverTimeTotal: totalValues.cardsOverTimeTotal,
            topResourcesTotal: totalValues.topResourcesTotal,
            transactionsOverTimeTotal: totalValues.transactionsOverTimeTotal,
            totalLaunches: totalValues.totalLaunches,
            totalInvocations: totalValues.totalInvocations,
            totalNoCardsInvocations: totalValues.totalNoCardsInvocations,
            totalCards: totalValues.totalCards,
            totalTransactions: totalValues.totalTransactions
        });
    }

    private getUpdatedTotalValues(visibleApps, visibleServices, visiblePractices, filterType) {
        let lotTotal = $.extend(true, {}, this.state.launchesOverTimeTotal);
        let iotTotal = $.extend(true, {}, this.state.invocationsOverTimeTotal);
        let cotTotal = $.extend(true, {}, this.state.cardsOverTimeTotal);
        let topTotal = $.extend(true, {}, this.state.topResourcesTotal);
        let trotTotal = $.extend(true, {}, this.state.transactionsOverTimeTotal);
        let lotEntries = $.extend(true, [], this.state.launchesOverTimeByFilter.data.entries);
        let iotEntries = $.extend(true, [], this.state.invocationsOverTimeByFilter.data.entries);
        let nciotEntries = $.extend(true, [], this.state.noCardsInvocationsOverTimeByFilter.data.entries);
        let cotEntries = $.extend(true, [], this.state.cardsOverTimeByFilter.data.entries);
        let topEntries = $.extend(true, [], this.state.topResourcesByFilter.data.entries);
        let trotEntries = $.extend(true, [], this.state.transactionsOverTimeByFilter.data.entries);
        let lotData = [];
        let iotData = [];
        let cotData = [];
        let topData = [];
        let trotData = [];
        let totalLaunches = 0;
        let totalInvocations = 0;
        let totalNoCardsInvocations = 0;
        let totalCards = 0;
        let totalTransactions = 0;
        lotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            if (filterType === "practices") {
                visiblePractices.forEach((practice) => {
                    curEntry["total"] += entry[practice];
                });
            } else [
                visibleApps.forEach(app => {
                    curEntry["total"] += entry[app];
                })
            ]
            totalLaunches += curEntry["total"];
            lotData.push(curEntry);
        });
        lotTotal.data.entries = lotData;

        iotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleServices.forEach(service => {
                if (entry[service]) {
                    curEntry["total"] += entry[service];
                }
            });
            totalInvocations += curEntry["total"];
            iotData.push(curEntry);
        });
        iotTotal.data.entries = iotData;
        nciotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleServices.forEach(service => {
                if (entry[service]) {
                    curEntry["total"] += entry[service];
                }
            });
            totalNoCardsInvocations += curEntry["total"];
        });
        cotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleServices.forEach(service => {
                if (entry[service]) {
                    curEntry["total"] += entry[service];
                }
            });
            totalCards += curEntry["total"];
            cotData.push(curEntry);
        });
        cotTotal.data.entries = cotData;
        topEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            if (filterType === "practices") {
                visiblePractices.forEach((practice) => {
                    curEntry["total"] += entry[practice];
                });
            } else {
                visibleApps.forEach(app => {
                    if (entry[app]) {
                        curEntry["total"] += entry[app];
                    }
                });
                visibleServices.forEach(service => {
                    if (entry[service]) {
                        curEntry["total"] += entry[service];
                    }
                });
            }
            topData.push(curEntry);
        });
        topData.sort((a, b) => (a["total"] > b["total"]) ? -1 : ((b["total"] > a["total"]) ? 1 : 0))
        topTotal.data.entries = topData;
        trotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            if (filterType === "practices") {
                visiblePractices.forEach((practice) => {
                    curEntry["total"] += entry[practice];
                });
            } else {
                visibleApps.forEach(app => {
                    if (entry[app]) {
                        curEntry["total"] += entry[app];
                    }
                });
                visibleServices.forEach(service => {
                    if (entry[service]) {
                        curEntry["total"] += entry[service];
                    }
                });
            }
            totalTransactions += curEntry["total"];
            trotData.push(curEntry);
        });
        trotTotal.data.entries = trotData;

        return {
            launchesOverTimeTotal: lotTotal,
            invocationsOverTimeTotal: iotTotal,
            cardsOverTimeTotal: cotTotal,
            topResourcesTotal: topTotal,
            transactionsOverTimeTotal: trotTotal,
            totalLaunches: {
                data: totalLaunches,
                refreshing: false
            },
            totalInvocations: {
                data: totalInvocations,
                refreshing: false
            },
            totalNoCardsInvocations: {
                data: totalNoCardsInvocations,
                refreshing: false
            },
            totalCards: {
                data: totalCards,
                refreshing: false
            },
            totalTransactions: {
                data: totalTransactions,
                refreshing: false
            }
        };
    }

    private handleToggle(e) {
        let toggled = e.target.checked;
        if (toggled) {
            let va = [];
            let vs = [];
            let vp = [];
            this.state.allActivatedApps.forEach((app) => {
                va.push(app.appId);
            });
            this.state.allActivatedServices.forEach((service) => {
                vs.push(service.id);
            });
            this.state.allPractices.forEach((practice) => {
                vp.push(practice.name);
            });
            this.handleVisibleItemsChange(va, vs, vp, this.state.filterType);
        } else {
            this.handleVisibleItemsChange([], [], [], this.state.filterType);
        }
    }

    private isFilterResourcesActive = () => {
        if (this.state.filterType === 'practices') {
            return this.state.filteredPractices.length !== this.state.allPractices.length;
        }
        return this.state.visibleApps.length !== this.state.allActivatedApps.length || this.state.visibleServices.length !== this.state.allActivatedServices.length;
    }

    private handleTimeSpanChange = e => {
        let startOf;
        let interval;
        let value = e.target.value;
        let timeframeValue = value;
        switch (value) {
            case 0:
                startOf = "day"
                interval = "hour"
                break;
            case 86400000:
                startOf = "day"
                interval = "hour"
                break;
            case 604800000:
                startOf = "day"
                interval = "day"
                break;
            case 2678400000:
                startOf = "day"
                interval = "day"
                break;
            case 8035200000:
                startOf = "week"
                interval = "week"
                break;
            case 31536000000:
                startOf = "month"
                interval = "month"
                break;
            case -12:
                value = this.calculateTimeframe("year")
                timeframeValue = -12;
                startOf = "day";
                if (value < 86400000) {
                    interval = "hour"
                } else if (value < 2678400000) {
                    interval = "day"
                } else if (value < 10713600000) {
                    interval = "week"
                } else {
                    interval = "month"
                }
                break;
        }
        this.setState({timeframe: value, timeframeValue, interval, startOf}, this.refreshAll);
    };

    private calculateTimeframe = startOf => {
        let startDate = moment(this.state.endTime).tz(this.props.ui.timeZone).startOf(startOf);
        let endDate = moment(this.state.endTime).tz(this.props.ui.timeZone);
        let timeframe = endDate.diff(startDate);
        return timeframe;
    };

    private exportTransactionsCsv = () => {
        this.setState({
            snackbar: {
                open: true,
                message: "Downloading CSV...",
                autoHideDuration: null
            }
        });
        const endTime = new Date().getTime();
        const startTime = this.state.startTime;
        const accountName = this.props.accounts.selected.data.name;
        const environmentId = this.props.gateways.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, this.props.environments);
        const gatewayName = this.props.gateways.selected.data.name;
        const query = {
            startTime,
            endTime,
            gatewayId: this.props.gateways.selected.data.gatewayId,
            accountId: this.props.gateways.selected.data.accountId,
            environmentId,
            allActivatedClients: this.state.allActivatedApps,
            timeZone: this.props.ui.timeZone
        }
        axiosWrapper(this.props.config.analyticsService, "gtw-export-transactions", "POST", query, {}, "blob")
            .then(response => {
                let fileName = `${accountName}_${environmentName}_${gatewayName}_gtw-transactions-${getFullDateDisplay(Date.now(), this.props.ui.timeZone)}.csv`;
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    (window.navigator as any).msSaveOrOpenBlob(new Blob([response.data]), fileName);
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const sanitizedUrl = encodeURI(url);
                    const link = document.createElement("a");
                    link.href = sanitizedUrl;
                    let sanitizedFileName = encodeURI(fileName);
                    link.setAttribute("download", sanitizedFileName);
                    document.body.appendChild(link);
                    link.click();
                }
                this.setState({
                    snackbar: {
                        open: true,
                        message: "CSV Downloaded",
                        autoHideDuration: 4000
                    }
                });
            })
            .catch((error) => {
                consoleLogger.log(error);
                this.setState({
                    snackbar: {
                        open: true,
                        message: "Error Downloading CSV",
                        autoHideDuration: 4000
                    }
                });
            });
    }

    private exportLaunchesCsv = () => {
        this.setState({
            snackbar: {
                open: true,
                message: "Downloading CSV...",
                autoHideDuration: null
            }
        });
        const endTime = new Date().getTime();
        const startTime = this.state.startTime;
        const accountName = this.props.accounts.selected.data.name;
        const environmentId = this.props.gateways.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, this.props.environments);
        const gatewayName = this.props.gateways.selected.data.name;
        const query = {
            startTime,
            endTime,
            gatewayId: this.props.gateways.selected.data.gatewayId,
            accountId: this.props.gateways.selected.data.accountId,
            environmentId,
            allActivatedClients: this.state.allActivatedApps,
            timeZone: this.props.ui.timeZone
        }
        axiosWrapper(this.props.config.analyticsService, "gtw-export-launches", "POST", query, {}, "blob")
            .then(response => {
                let fileName = `${accountName}_${environmentName}_${gatewayName}_gtw-launches-${getFullDateDisplay(Date.now(), this.props.ui.timeZone)}.csv`;
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    (window.navigator as any).msSaveOrOpenBlob(new Blob([response.data]), fileName);
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const sanitizedUrl = encodeURI(url);
                    const link = document.createElement("a");
                    link.href = sanitizedUrl;
                    let sanitizedFileName = encodeURI(fileName);
                    link.setAttribute("download", sanitizedFileName);
                    document.body.appendChild(link);
                    link.click();
                }
                this.setState({
                    snackbar: {
                        open: true,
                        message: "CSV Downloaded",
                        autoHideDuration: 4000
                    }
                });
            })
            .catch((error) => {
                consoleLogger.log(error);
                this.setState({
                    snackbar: {
                        open: true,
                        message: "Error Downloading CSV",
                        autoHideDuration: 4000
                    }
                });
            });
    }

    private exportInvocationsCsv = () => {
        this.setState({
            snackbar: {
                open: true,
                message: "Downloading CSV...",
                autoHideDuration: null
            }
        });
        const endTime = new Date().getTime();
        const startTime = this.state.startTime;
        const accountName = this.props.accounts.selected.data.name;
        const environmentId = this.props.gateways.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, this.props.environments);
        const gatewayName = this.props.gateways.selected.data.name;
        const query = {
            startTime,
            endTime,
            gatewayId: this.props.gateways.selected.data.gatewayId,
            accountId: this.props.gateways.selected.data.accountId,
            environmentId,
            allActivatedServices: this.state.allActivatedServices,
            timeZone: this.props.ui.timeZone
        }
        axiosWrapper(this.props.config.analyticsService, "gtw-export-invocations", "POST", query, {}, "blob")
            .then(response => {
                let fileName = `${accountName}_${environmentName}_${gatewayName}_gtw-invocations-${getFullDateDisplay(Date.now(), this.props.ui.timeZone)}.csv`;
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    (window.navigator as any).msSaveOrOpenBlob(new Blob([response.data]), fileName);
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const sanitizedUrl = encodeURI(url);
                    const link = document.createElement("a");
                    link.href = sanitizedUrl;
                    let sanitizedFileName = encodeURI(fileName);
                    link.setAttribute("download", sanitizedFileName);
                    document.body.appendChild(link);
                    link.click();
                }
                this.setState({
                    snackbar: {
                        open: true,
                        message: "CSV Downloaded",
                        autoHideDuration: 4000
                    }
                });
            })
            .catch((error) => {
                consoleLogger.log(error);
                this.setState({
                    snackbar: {
                        open: true,
                        message: "Error Downloading CSV",
                        autoHideDuration: 4000
                    }
                });
            });
    }

}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Analytics));
