const allscripts = ["DataSourceAllscriptsDstu2", "DataAdapterAllscriptsUnity"];
const cerner = ["DataSourceCernerDstu2", "DataSourceCernerR4"];
const epic = ["DataSourceEpicDstu2", "DataSourceEpicR4", "DataSourceEpicR4External"];
const logica = ["DataSourceHspc"];
const fhir = ["DataSourceFhirDstu2Proxy", "DataSourceFhirStu3Proxy", "DataSourceDstu2ToR4", "DataSourceFhirR4Proxy"];
const flatiron = ["DataSourceFlatironOncoEmrDstu2"];

export default function (type = ""): string {
    let vendor = "unknown";

    if (fhir.includes(type)) { vendor = "fhir" }
    else if (logica.includes(type)) { vendor = "logica" }
    else if (allscripts.includes(type)) { vendor = "allscripts" }
    else if (cerner.includes(type)) { vendor = "cerner" }
    else if (epic.includes(type)) { vendor = "epic" }
    else if (flatiron.includes(type)) { vendor = "flatiron" }

    return vendor;
}
