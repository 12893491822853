import * as React from "react";
import * as PropTypes from "prop-types";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {ArrowForward} from "@material-ui/icons";
import {getFullDateTimeDisplay} from "../../../../../../lib/utils";
import ChipDropDown from "../../../../../Widgets/ChipDropDown";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import moment from "moment";

class FilterByDate extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        setFilterItem: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {
            startTime: 0,
            endTime: 0,
            timeframe: this.props.state.timeframe || 0,
            startDate: this.props.state.startDate || new Date(),
            endDate: this.props.state.endDate || new Date()
        };
    }

    public render() {
        let labelTimeframe = "Today";
        switch (this.state.timeframe) {
            case -1: {
                const from = this.state.startDate ? getFullDateTimeDisplay(moment(this.state.startDate), this.props.ui.timeZone) : "";
                const to = this.state.endDate ? getFullDateTimeDisplay(moment(this.state.endDate), this.props.ui.timeZone) : "";
                labelTimeframe = `From (${from}) To (${to})`;
                break;
            }
            case 604800000:
                labelTimeframe = "Last 7 days";
                break;
            case 2592000000:
                labelTimeframe = "Last 30 days";
                break;
            case 7776000000:
                labelTimeframe = "Last 90 days";
                break;
        }

        return <div>
            <ChipDropDown label={labelTimeframe} inactiveBackground="#fff" inactiveColor={this.props.muiTheme.palette.textColor} activeBackground={this.props.muiTheme.palette.primary1Color}
                activeColor="#fff" deleteIconStyle={{fill: "rgba(255, 255, 255, 0.67)"}} onClose={this.handleTimeSpanChange}
                popover={{
                    title: "Timeframe",
                    content: <div style={{margin: "8px 16px"}}>
                        <RadioGroup name="timeframeRadioGroup" value={this.state.timeframe} onChange={this.handleTimeSpanChange}>
                            <FormControlLabel value={0} control={<Radio color="primary" style={{padding: "2px"}}/>} label="Today"/>
                            <FormControlLabel value={604800000} control={<Radio color="primary" style={{padding: "2px"}}/>} label="Last 7 days"/>
                            <FormControlLabel value={2592000000} control={<Radio color="primary" style={{padding: "2px"}}/>} label="Last 30 days"/>
                            <FormControlLabel value={7776000000} control={<Radio color="primary" style={{padding: "2px"}}/>} label="Last 90 days"/>
                            <FormControlLabel value={-1} control={<Radio color="primary" style={{padding: "2px"}}/>} label="Custom date range"/>
                        </RadioGroup>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={this.state.startDate} emptyLabel="From" maxDate={this.state.endDate} disabled={this.state.timeframe !== -1}
                                    onChange={e => this.updateDate("startDate", e)} style={{maxWidth: "100px"}}/>
                                <ArrowForward style={{margin: "15px 0"}}/>
                                <DatePicker value={this.state.endDate} emptyLabel="Until" minDate={this.state.startDate} disabled={this.state.timeframe !== -1}
                                    onChange={e => this.updateDate("endDate", e)} style={{maxWidth: "100px"}}/>
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                }}/>
        </div>;
    }

    private updateDate = (date, value) => {
        let state = {};
        !this.state[date] && (date === "startDate"
            ? (value = moment(value).startOf("day").toDate())
            : (value = moment(value).endOf("day").toDate()));
        state[date] = value;
        this.setState(state);
    }

    private handleTimeSpanChange = e => {
        if (e) {
            let timeframe = parseInt(e.target.value);
            this.setState({timeframe});
            if (timeframe !== -1) {
                const startTime = timeframe === 0 ? moment().startOf("day") : Date.now() - timeframe;
                this.props.setFilterItem("startTime", startTime, timeframe);
            }
        } else if (this.state.startDate && this.state.endDate && this.state.timeframe === -1) {
            const startDate = moment(this.state.startDate).startOf("day");
            const endDate = moment(this.state.endDate).endOf("day");
            if (this.state.startTime) {
                const startTime = moment(this.state.startTime);
                startDate.hours(startTime.hours());
                startDate.minutes(startTime.minutes());
            }
            if (this.state.endTime) {
                const endTime = moment(this.state.endTime);
                endDate.hours(endTime.hours());
                endDate.minutes(endTime.minutes());
            }

            this.setState({startDate: startDate.toDate(), endDate: endDate.toDate()});

            this.props.setFilterItem("period", {startTime: startDate, endTime: endDate});
        }
    };
}

export default FilterByDate
