import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import colors from "../../../../../../lib/legacy-color-palette";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import withLegacyTheme from "../../../../../../lib/hoc/with-legacy-theme";
import * as actions from "../../../../../../redux/actions/index";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
// import RefreshIndicator from "@material-ui/RefreshIndicator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateRange from "@material-ui/icons/DateRange";
import AppLaunchesOT from "../../../../Apps/AppEdit/Usage/UsageGraphs/AppLaunchesOT/index"
import TopResourcesHorizontal from "../../../../Apps/AppEdit/Usage/UsageGraphs/TopResourcesHorizontal/index";
import ChipDropDown from "../../../../../Widgets/ChipDropDown/index";
// import {getDateDisplay, getDateWithZoneDisplay} from "../../../../../../lib/utils";
import TotalCount from "../../../../../Widgets/Editor/UsageTab/TotalCount";
import "./style.less";

let moment = require("moment-timezone");
const colorQueue = [
    colors.deepPurple600,
    colors.teal600,
    colors.amber600,
    colors.blue600,
    colors.brown600,
    colors.deepOrange600,
    colors.green600,
    colors.red600,
    colors.yellow600,
    colors.lime600
];

export class ServiceAnalytics extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            assignedColors: {},
            topResourcesByAct: {
                data: null,
                refreshing: false
            },
            topResourcesTotal: {
                data: null,
                refreshing: false
            },
            invocationsOverTimeByAct: {
                data: null,
                refreshing: false
            },
            invocationsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            cardsOverTimeByAct: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeByAct: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            totalTransactions: {
                data: null,
                refreshing: false
            },
            totalInvocations: {
                data: null,
                refreshing: false
            },
            totalCards: {
                data: null,
                refreshing: false
            },
            timeframe: 2678400000,
            timeframeValue: 2678400000,
            startTime: 0,
            endTime: 0,
            timeZone: "US/Mountain",
            startOf: "day",
            interval: "day",
            allActivations: [],
            visibleActivations: [],
            filteredActivations: [],
            snackbar: {
                open: false,
                message: "Downloading CSV...",
                autoHideDuration: null
            },
            selectedServiceId: null
        };

        this.topResources_init = this.topResources_init.bind(this);
        this.topResources_refresh = this.topResources_refresh.bind(this);
        this.invocationsOverTime_init = this.invocationsOverTime_init.bind(this);
        this.invocationsOverTime_refresh = this.invocationsOverTime_refresh.bind(this);
        this.handleTimeSpanChange = this.handleTimeSpanChange.bind(this);
        this.refreshAll = this.refreshAll.bind(this);
        this.handleActivationsToggle = this.handleActivationsToggle.bind(this);
        this.updateTotalValues = this.updateTotalValues.bind(this);
        this.handleVisibleActivationsChange = this.handleVisibleActivationsChange.bind(this)
    }

    public componentDidMount() {
        this.setState({
            endTime: new Date().getTime(),
            allActivations: this.props.cdsServiceSets.selected.data.activations
        }, () => {
            this.setVisibleActivations();
            this.assignChartColors();
            this.getChartStartTimeInMillis();
        });
    }

    public render() {
        const styles = {
            cardChart: {
                display: "inline-block",
                margin: "5px"
            },
            cardChartText: {padding: 0},
            chartMargins: {top: 15, right: 30, bottom: 5, left: 0},
            subTitle: {margin: 0},
            title: {marginBottom: 0}
        };

        return <div style={{width: "100%"}}>
            <div className="container app-analytics">
                {this.renderToolbar()}
                {this.state.selectedServiceId && <div>
                    <TotalCount items={{
                        totalInvocations: { ...this.state.totalInvocations, displayName: "CDS Service Invocations" },
                        totalCards: { ...this.state.totalCards, displayName: "CDS Cards Returned" },
                        totalTransactions: { ...this.state.totalTransactions, displayName: "FHIR Transactions" } }} />
                    <div style={{margin: "30px 10px 10px"}}>Comparing by Activation</div>
                    <Card id="chart-top-resources" className="app-chart-card">
                        {this.invocationsOverTimeByAct_render(styles)}
                        <Divider style={{marginTop: "10px", marginLeft: "50px", marginRight: "50px"}}/>
                        {this.cardsOverTimeByAct_render(styles)}
                        <Divider style={{marginTop: "10px", marginLeft: "50px", marginRight: "50px"}}/>
                        {this.topResourcesByAct_render(styles)}
                        <Divider style={{marginTop: "10px", marginLeft: "50px", marginRight: "50px"}}/>
                        {this.transactionsOverTimeByAct_render(styles)}
                    </Card>
                </div>}
                <br/>
                <br/>
                <Divider/>
            </div>
        </div>;
    }

    private init() {
        Promise.all([this.topResources_init(), this.invocationsOverTime_init(), this.transactionsOverTime_init()])
            .then(([topData, iotData, trotData]) => {
                this.setState({
                    topResourcesByAct: {
                        data: this.topResourcesByAct_postProcess(topData),
                        refreshing: false
                    },
                    topResourcesTotal: {
                        data: this.topResourcesTotal_postProcess(topData),
                        refreshing: false
                    },
                    invocationsOverTimeByAct: {
                        data: this.invocationsOverTimeByAct_postProcess(iotData),
                        refreshing: false
                    },
                    invocationsOverTimeTotal: {
                        data: this.invocationsOverTimeTotal_postProcess(iotData),
                        refreshing: false
                    },
                    cardsOverTimeByAct: {
                        data: this.cardsOverTimeByAct_postProcess(iotData),
                        refreshing: false
                    },
                    totalCards: {
                        data: this.calculateTotalCards(iotData),
                        refreshing: false
                    },
                    totalInvocations: {
                        data: this.calculateTotalInvocations(iotData),
                        refreshing: false
                    },
                    transactionsOverTimeByAct: {
                        data: this.transactionsOverTimeByAct_postProcess(trotData),
                        refreshing: false
                    },
                    transactionsOverTimeTotal: {
                        data: this.transactionsOverTimeTotal_postProcess(trotData),
                        refreshing: false
                    },
                    totalTransactions: {
                        data: this.calculateTotalTransactions(trotData),
                        refreshing: false
                    }
                }, this.handleVisibleActivationsChange.bind(this, this.state.visibleActivations));
            });
    }

    private refreshAll() {
        this.topResources_refresh();
        this.invocationsOverTime_refresh();
        this.transactionsOverTime_refresh();
        this.init();
    }

    private setVisibleActivations() {
        let va = [];
        this.state.allActivations.forEach(activation => va.push(activation.name));
        this.setState({visibleActivations: va, filteredActivations: va});
    }

    private assignChartColors() {
        let assignedColors = {
            "total": colors.indigo600
        };
        for (let x = 0; x < this.state.allActivations.length; x++) {
            let colorIndex = x;
            if (colorIndex > colorQueue.length) {
                colorIndex = colorIndex % colorQueue.length;
            }
            assignedColors[this.state.allActivations[x].name] = colorQueue[colorIndex];
        }
        this.setState({assignedColors});
    }

    private getChartStartTimeInMillis() {
        let midnight = moment.tz(this.state.endTime - this.state.timeframe, this.props.ui.timeZone).startOf(this.state.startOf);
        this.setState({startTime: new Date(midnight.format()).getTime()});
        return new Date(midnight.format()).getTime();
    }

    private runAnalyticsQuery(dataType, query) {
        return axiosWrapper(this.props.config.analyticsService, dataType, "POST", query)
    }

    private getTimeZone() {
        return this.props.ui.timeZone
    }

    private cardsOverTimeByAct_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allActivations.length; i++) {
            let curActivation = this.state.allActivations[i];
            preparedData.series.push(curActivation.name);
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let gtwKey = key.split(":")[1];
                let activationKey;
                for (let activation of this.state.allActivations) {
                    if (gtwKey === activation.gatewayId) {
                        activationKey = activation.name
                    }
                }
                curData[activationKey] = curBucket["3"]["buckets"][key]["4"].value;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private calculateTotalCards(rawData) {
        let totalCards = 0;
        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];

            for (let key in curBucket["3"]["buckets"]) {
                totalCards += curBucket["3"]["buckets"][key]["4"].value;
            }
        }

        return totalCards;
    };


    private cardsOverTimeByAct_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.cardsOverTimeByAct.data} styles={styles} launchesOverTime_refresh={this.invocationsOverTime_refresh}
            title="CDS Cards Returned By Activation"/>
    }

    private topResources_init() {
        let query = this.topResources_preProcess();
        return this.runAnalyticsQuery("cds-top-data", query).then(res => res.data);
    }

    private topResources_preProcess() {
        const {accountId} = getHostnameInfo();
        const environmentId = this.props.cdsServiceSets.selected.data.environmentId;
        const cdsServiceId = this.state.selectedServiceId;
        const cdsServiceSetId = this.props.cdsServiceSets.selected.data.cdsServiceSetId;
        const clientId = accountId + "/" + environmentId + "/" + cdsServiceSetId + "/" + cdsServiceId;

        let query = {
            clientId,
            endTime: new Date().getTime(),
            startTime: this.getChartStartTimeInMillis(),
            environmentId,
            accountId
        }

        return query;
    }

    private topResourcesByAct_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allActivations.length; i++) {
            let curActivation = this.state.allActivations[i];
            preparedData.series.push(curActivation.name);
        }

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;

        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            const envBuckets = curBucket.group_by_gtw.buckets;

            for (let j = 0; j < envBuckets.length; j++) {
                let curEnvBucket = envBuckets[j];
                let gtwKey = curEnvBucket.key
                let activationKey;
                for (let activation of this.state.allActivations) {
                    if (gtwKey === activation.gatewayId) {
                        activationKey = activation.name
                    }
                }
                curData[activationKey] = curEnvBucket.doc_count;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                datapoint["name"] = resources[i];
                datapoint["demo"] = 0;
                placeholeder.push(datapoint)
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    };

    private topResourcesTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;
        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                datapoint["name"] = resources[i];
                datapoint["demo"] = 0;
                placeholeder.push(datapoint)
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    };

    private topResources_refresh() {
        this.setState({
            topResourcesByAct: {
                data: this.state.topResourcesByAct.data,
                refreshing: true
            },
            topResourcesTotal: {
                data: this.state.topResourcesTotal.data,
                refreshing: true
            }
        });
    }

    private topResourcesByAct_render(styles) {
        return <TopResourcesHorizontal state={this.state} data={this.state.topResourcesByAct.data} styles={styles} topResources_refresh={this.topResources_refresh} legend={true}
            title="Resource Transactions By Activation"/>
    };

    private invocationsOverTime_init() {
        let query = this.invocationsOverTime_preProcess();
        return this.runAnalyticsQuery("cds-iot-data", query).then(res => res.data);
    }

    private invocationsOverTime_preProcess() {

        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }

        let gateways = [];
        this.props.gateways.all.data.forEach((gateway) => {
            gateways.push(gateway.gatewayId);
        })

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            cdsServiceId: this.state.selectedServiceId,
            cdsServiceSetId: this.props.cdsServiceSets.selected.data.cdsServiceSetId,
            gateways,
            accountId: this.props.cdsServiceSets.selected.data.accountId,
            environmentId: this.props.cdsServiceSets.selected.data.environmentId
        }

        return query;
    }

    private invocationsOverTimeByAct_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allActivations.length; i++) {
            let curActivation = this.state.allActivations[i];
            preparedData.series.push(curActivation.name);
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let gtwKey = key.split(":")[1];
                let activationKey;
                for (let activation of this.state.allActivations) {
                    if (gtwKey === activation.gatewayId) {
                        activationKey = activation.name
                    }
                }
                curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private invocationsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private calculateTotalInvocations(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private invocationsOverTime_refresh() {
        this.setState({
            invocationsOverTimeByAct: {
                data: this.state.invocationsOverTimeByAct.data,
                refreshing: true
            },
            invocationsOverTimeTotal: {
                data: this.state.invocationsOverTimeTotal.data,
                refreshing: true
            },
            totalInvocations: {
                data: this.state.totalInvocations.data,
                refreshing: true
            },
            cardsOverTimeByAct: {
                data: this.state.cardsOverTimeByAct.data,
                refreshing: true
            },
            totalCards: {
                data: this.state.totalCards.data,
                refreshing: true
            }
        });
    }

    private invocationsOverTimeByAct_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.invocationsOverTimeByAct.data} styles={styles}
            launchesOverTime_refresh={this.invocationsOverTime_refresh} title="CDS Service Invocations By Activation"/>
    }

    private transactionsOverTime_init() {
        let query = this.transactionsOverTime_preProcess();
        return this.runAnalyticsQuery("cds-trot-data", query).then(res => res.data);
    }

    private transactionsOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }
        const accountId = this.props.cdsServiceSets.selected.data.accountId;
        const environmentId = this.props.cdsServiceSets.selected.data.environmentId;
        const cdsServiceId = this.state.selectedServiceId;
        const cdsServiceSetId = this.props.cdsServiceSets.selected.data.cdsServiceSetId;
        const clientId = accountId + "/" + environmentId + "/" + cdsServiceSetId + "/" + cdsServiceId;
        let gateways = [];
        this.props.gateways.all.data.forEach(gateway => gateways.push(gateway.gatewayId))

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            clientId,
            gateways,
            accountId,
            environmentId
        }

        return query;
    }

    private transactionsOverTimeByAct_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allActivations.length; i++) {
            let curActivation = this.state.allActivations[i];
            preparedData.series.push(curActivation.name);
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let gtwKey = key.split(":")[1];
                let activationKey;
                for (let activation of this.state.allActivations) {
                    if (gtwKey === activation.gatewayId) {
                        activationKey = activation.name
                    }
                }
                curData[activationKey] = curBucket["3"]["buckets"][key].doc_count;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private transactionsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private calculateTotalTransactions(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private transactionsOverTime_refresh() {
        this.setState({
            transactionsOverTimeByAct: {
                data: this.state.transactionsOverTimeByAct.data,
                refreshing: true
            },
            transactionsOverTimeTotal: {
                data: this.state.transactionsOverTimeTotal.data,
                refreshing: true
            },
            totalTransactions: {
                data: this.state.totalTransactions.data,
                refreshing: true
            }
        });
    }

    private transactionsOverTimeByAct_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.transactionsOverTimeByAct.data} styles={styles}
            launchesOverTime_refresh={this.transactionsOverTime_refresh} title="Resource Transactions By Activation"/>
    }

    private renderToolbar = () => {
        // let timeframe = getDateDisplay(this.state.startTime, this.props.ui.timeZone) + " - " + getDateWithZoneDisplay(this.state.endTime, this.props.ui.timeZone);
        let labelActivations = "Activations";
        if (this.state.filteredActivations.length && this.state.filteredActivations.length !== this.state.allActivations.length) {
            labelActivations = this.state.filteredActivations[0];
            if (this.state.filteredActivations.length > 1) {
                labelActivations += ` + ${this.state.filteredActivations.length - 1}`;
            }
        } else if (this.state.filteredActivations.length === 0) {
            labelActivations = "Activations";
        }

        let serviceMenuItems = this.props.cdsServiceSets.selected.data.cdsServices.map((service, i) => <MenuItem value={service.id} key={`service-id-${i}`}>{service.title}</MenuItem>);

        return <Toolbar className={"analytics-toolbar"} style={{height: "76px"}}>
            <div style={{alignItems: "none"}}>
                <DateRange style={{fontSize: "24px", paddingRight: "10px", alignSelf: "center"}}/>
                <Select value={this.state.timeframeValue} onChange={this.handleTimeSpanChange}
                    // selectedMenuItemStyle={{"color": "rgb(3, 155, 229)"}} errorText={timeframe} errorStyle={{color: "gray"}}
                    style={{width: "200px", marginRight: "20px"}}
                >
                    <MenuItem value={0}>Today</MenuItem>
                    <MenuItem value={86400000}>Yesterday</MenuItem>
                    <MenuItem value={604800000}>Last 7 days</MenuItem>
                    <MenuItem value={2678400000}>Last 30 days</MenuItem>
                    <MenuItem value={8035200000}>Last 90 days</MenuItem>
                    <MenuItem value={31536000000}>Last 365 days</MenuItem>
                    <MenuItem value={-12}>Year-to-date</MenuItem>
                </Select>
                <Select
                    value={this.state.selectedServiceId}
                    onChange={this.handleSelectedServiceChange}
                    style={{
                        width: '400px',
                        marginRight: '20px',
                    }}
                    // selectedMenuItemStyle={{"color": "rgb(3, 155, 229)"}}  errorText={(!this.state.selectedServiceId && "You need to have a service selected") || "Selected service"}
                    // errorStyle={{color: (!this.state.selectedServiceId && "red") || "gray"}}
                >
                    {serviceMenuItems}
                </Select>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div>
                    <ChipDropDown label={labelActivations} inactiveBackground="#fff" inactiveColor={this.props.muiTheme.palette.textColor}
                        activeBackground={this.props.muiTheme.palette.primary1Color} activeColor="#fff" isActive={this.isFilterResourcesActive()}
                        popover={{
                            title: "Activations",
                            content: <div style={{margin: "8px 16px"}}>
                                <FormControlLabel
                                    control={<Switch checked={this.state.filteredActivations.length === this.state.allActivations.length} style={{marginBottom: "8px"}} onChange={this.handleActivationsToggle} />}
                                    label="Select All Activations"
                                />
                                <Divider style={{margin: "8px 0px"}}/>
                                {this.state.allActivations.sort((a, b) => a.name.localeCompare(b.name)).map((activation) => {
                                    let index = this.state.filteredActivations.indexOf(activation.name);
                                    return <FormControlLabel
                                        control={<Checkbox name={`activation-${activation.name}`} checked={index >= 0}
                                            onChange={(event) => {
                                                let va = $.extend(true, [], this.state.filteredActivations);
                                                if (event.target.checked && index < 0) {
                                                    va.push(activation.name)
                                                } else if (!event.target.checked && index >= 0) {
                                                    va.splice(index, 1);
                                                }
                                                this.handleVisibleActivationsChange(va);
                                            }}/>}
                                        label={activation.name} key={`activation-${activation.name}`}/>
                                })}
                            </div>
                        }}
                        onRequestDelete={() => {
                            let va = [];
                            this.state.allActivations.forEach(activation => va.push(activation.name));
                            this.handleVisibleActivationsChange(va);
                        }}/>
                </div>
                <IconButton onClick={this.refreshAll} disabled={this.state.topResourcesByAct.refreshing}
                    disableTouchRipple={true} style={{alignSelf: "center", margin: "10px 0 10px 10px"}}>
                    {/* <RefreshIndicator percentage={100} size={30} left={10} top={9} status={this.state.topResourcesByAct.refreshing ? "loading" : "ready"}/> */}
                </IconButton>
            </div>
        </Toolbar>;
    };

    private handleVisibleActivationsChange(visibleActivations) {
        let va = $.extend(true, [], visibleActivations);
        if (va.length === 0) {
            this.state.allActivations.forEach(activation => va.push(activation.name));
        }
        let iot = $.extend(true, {}, this.state.invocationsOverTimeByAct);
        let top = $.extend(true, {}, this.state.topResourcesByAct);
        let trot = $.extend(true, {}, this.state.transactionsOverTimeByAct);
        iot.data.series = va;
        top.data.series = va;
        trot.data.series = va;
        this.setState({
            filteredActivations: visibleActivations,
            visibleActivations: va,
            invocationsOverTimeByAct: iot,
            topResourcesByAct: top,
            transactionsOverTimeByAct: trot
        }, this.updateTotalValues);
    }

    private updateTotalValues() {
        let iotTotal = $.extend(true, {}, this.state.invocationsOverTimeTotal);
        let topTotal = $.extend(true, {}, this.state.topResourcesTotal);
        let trotTotal = $.extend(true, {}, this.state.transactionsOverTimeTotal);
        let iotEntries = $.extend(true, [], this.state.invocationsOverTimeByAct.data.entries);
        let topEntries = $.extend(true, [], this.state.topResourcesByAct.data.entries);
        let trotEntries = $.extend(true, [], this.state.transactionsOverTimeByAct.data.entries);
        let cardEntries = $.extend(true, [], this.state.cardsOverTimeByAct.data.entries);
        const visibleActivations = this.state.visibleActivations;
        let iotData = [];
        let topData = [];
        let trotData = [];
        let totalInvocations = 0;
        let totalTransactions = 0;
        let totalCards = 0;
        iotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleActivations.forEach(activation => curEntry["total"] += entry[activation])
            totalInvocations += curEntry["total"];
            iotData.push(curEntry);
        });
        iotTotal.data.entries = iotData;

        topEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleActivations.forEach(activation => {
                if (entry[activation]) {
                    curEntry["total"] += entry[activation];
                }
            })
            topData.push(curEntry);
        });
        topData.sort((a, b) => (a["total"] > b["total"]) ? -1 : ((b["total"] > a["total"]) ? 1 : 0))
        topTotal.data.entries = topData;
        trotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleActivations.forEach(activation => curEntry["total"] += entry[activation])
            totalTransactions += curEntry["total"];
            trotData.push(curEntry);
        });
        trotTotal.data.entries = trotData;

        cardEntries.forEach(entry => {
            let sumOfActivations = 0
            visibleActivations.forEach(activation => sumOfActivations += entry[activation])
            totalCards += sumOfActivations;
        });

        this.setState({
            invocationsOverTimeTotal: iotTotal,
            topResourcesTotal: topTotal,
            transactionsOverTimeTotal: trotTotal,
            totalInvocations: {
                data: totalInvocations,
                refreshing: false
            },
            totalTransactions: {
                data: totalTransactions,
                refreshing: false
            },
            totalCards: {
                data: totalCards,
                refreshing: false
            }
        });
    }

    private handleActivationsToggle(_event, toggled) {
        if (toggled) {
            let va = [];
            this.state.allActivations.forEach((activation) => {
                va.push(activation.name);
            });
            this.handleVisibleActivationsChange(va);
        } else {
            this.handleVisibleActivationsChange([]);
        }
    }

    private isFilterResourcesActive = () => this.state.visibleActivations.length !== this.state.allActivations.length;

    private handleTimeSpanChange = (event) => {
        let startOf;
        let interval;
        let value = event.target.value;
        let timeframeValue = value;
        switch (value) {
            case 0:
                startOf = "day"
                interval = "hour"
                break;
            case 86400000:
                startOf = "day"
                interval = "hour"
                break;
            case 604800000:
                startOf = "day"
                interval = "day"
                break;
            case 2678400000:
                startOf = "day"
                interval = "day"
                break;
            case 8035200000:
                startOf = "week"
                interval = "week"
                break;
            case 31536000000:
                startOf = "month"
                interval = "month"
                break;
            case -12:
                value = this.calculateTimeframe("year")
                timeframeValue = -12;
                startOf = "day";
                if (value < 86400000) {
                    interval = "hour"
                } else if (value < 2678400000) {
                    interval = "day"
                } else if (value < 10713600000) {
                    interval = "week"
                } else {
                    interval = "month"
                }
                break;
        }
        this.setState({timeframe: value, timeframeValue, interval, startOf}, this.refreshAll);
    };

    private calculateTimeframe = (startOf) => {
        var startDate = moment(this.state.endTime).tz(this.props.ui.timeZone).startOf(startOf);
        var endDate = moment(this.state.endTime).tz(this.props.ui.timeZone);
        var timeframe = endDate.diff(startDate);
        return timeframe;
    };

    private handleSelectedServiceChange = (event) => {
        this.setState({selectedServiceId: event.target.value}, this.init)
    };

}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(ServiceAnalytics));
