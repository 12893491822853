import * as React from 'react'
import {
    Card,
    CardContent,
    InputLabel
} from '@material-ui/core'
import { Link } from '@material-ui/icons'
import getGatewaysResourceUrl from '../../../../../../../lib/getGatewaysResourceUrl'
import getHostnameInfo from '../../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../../lib/getLocationInfo'
import Header from '../../../../../../Widgets/Editor/Header/'
import CustomLink from '../../../../../../Widgets/CustomLink'
import type { TStateSource as TSource } from '../..'

type TProps = {
    config: {
        proxyApi: {
            host: string,
            path: string,
            port: string,
            protocol: string,
        },
        reservedIds: Array<string>,
    },
    history: Array<string>,
    location: {},
    muiTheme: {
        palette: {
            primary1Color: string,
        }
    },
    state: {
        data: {
            configuration?: {
                fhirRepositoryDataSource?: string
                defaultDataSource: string,
            },
        },
        sources: Array<TSource>,
    },
}

const Component: React.FC<TProps> = (props: TProps) => {
    const { accountId } = getHostnameInfo()
    const { env, gatewayId } = getLocationInfo(props.location, props.config)

    const defaultSourceId = props.state.data.configuration?.defaultDataSource || ''
    const defaultSource = props.state.sources.find((source) => source.id === defaultSourceId) || {} as TSource
    const defaultSourceName = defaultSource.name || 'No default source'
    let defaultDataSourceLink = "";
    if (defaultSourceId.startsWith('cdr:') && defaultSource.id) {
        defaultDataSourceLink = `/${env}/cdrs/${defaultSourceId.slice(4)}`
    } else if (defaultSource.id && defaultSource.id) {
        defaultDataSourceLink = `/${env}/datasources/${defaultSourceId}`
    }

    // Do the same as above for default FHIR data source
    // We can't check the data source type and since this is very specific case if nothings is found we wont dispaly anything to not confuse the user
    const defaultFhirDataSourceId = props.state.data.configuration?.fhirRepositoryDataSource || ''
    const defaultFhirSource = props.state.sources.find((source) => source.id === defaultFhirDataSourceId) || {} as TSource
    const defaultFhirSourceName = defaultFhirSource.name || 'No default FHIR data source'
    let defaultFhirDataSourceLink = "";
    if (defaultFhirDataSourceId.startsWith('cdr:') && defaultFhirSource.id) {
        defaultFhirDataSourceLink = `/${env}/cdrs/${defaultFhirDataSourceId.slice(4)}`
    } else if (defaultFhirSource.id && defaultFhirSource.id) {
        defaultFhirDataSourceLink = `/${env}/datasources/${defaultFhirDataSourceId}`
    }

    return <Card style={{ marginTop: '48px' }}>
        <Header
            { ...props }
            leftIcon={
                <Link
                    style={{
                        paddingTop: '4px',
                        marginRight: '24px',
                    }}
                />}
            style={{
                height: '56px',
                opacity: .5
            }}
            primaryText={
                <div style={{paddingTop: '4px'}}>
                    {getGatewaysResourceUrl(accountId, env, gatewayId, props.config.proxyApi)}
                </div>
            }/>

        <CardContent>
            <CustomLink to={defaultDataSourceLink} fallbackText={defaultSourceName}>
                {/* <TextField
                    name='default-source'
                    label='Default Source'
                    value={defaultSourceName}
                    disabled
                    fullWidth
                    classes={{ root: 'disabled-text-field' }}
                    style={{ marginTop: '14px' }}
                    inputProps={{
                        style: {
                            fontWeight: 800,
                            color: defaultSource.id ? props.muiTheme.palette.primary1Color : undefined,
                            cursor: defaultSource.id ? 'pointer' : 'default',
                        },
                    }}/> */}
                <InputLabel
                    style={{
                        fontSize: '12px',
                        marginTop: '14px',
                        marginBottom: '10px',
                    }}>
                    Default Source
                </InputLabel>
                <span
                    className='disabled-text-field'
                    style={{
                        fontSize: '16px',
                        fontWeight: 800,
                        color: defaultSource.id ? props.muiTheme.palette.primary1Color : undefined,
                        cursor: defaultSource.id ? 'pointer' : 'default',
                    }}>
                    {defaultSourceName}
                </span>
            </CustomLink>
            {defaultFhirDataSourceId ? (<CustomLink to={defaultFhirDataSourceLink} fallbackText={defaultFhirSourceName}>
                <InputLabel
                    style={{
                        fontSize: '12px',
                        marginTop: '14px',
                        marginBottom: '10px',
                    }}>
                    FHIR Data source
                </InputLabel>
                <span
                    className='disabled-text-field'
                    style={{
                        fontSize: '16px',
                        fontWeight: 800,
                        color: defaultSource.id ? props.muiTheme.palette.primary1Color : undefined,
                        cursor: defaultSource.id ? 'pointer' : 'default',
                    }}>
                    {defaultFhirSourceName}
                </span>
            </CustomLink>) : null}
        </CardContent>
    </Card>
}

export default Component
